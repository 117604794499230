import React from "react";
import {NavLink} from "react-router-dom";

export default function MobileMenu(props)
{
    const {visibility, showMobileMenu, nav} = props;
    const MobileMenuClassName = visibility?'show':'';
    return(
        <div className={`offcanvas offcanvas-end ${MobileMenuClassName}`} id="offcanvasRightmobile">
            <div className="offcanvas-header py-0">
                <button type="button" className="close-menu mt-4" onClick={e=>showMobileMenu(false)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                         className="bi bi-x-circle-fill" viewBox="0 0 16 16">
                        <path
                            d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
                    </svg>
                </button>
            </div>
            <div className="offcanvas-body">
                <div className="head-contact d-block d-lg-none">
                   {/* <a href="index.html" className="logo-side">
                        <img src="images/logo.png" alt="logo" />
                    </a>*/}
                    <div className="mobile-menu-sec mt-3">
                        <ul>
                            {nav.map((item,i)=>
                                <li
                                    className="active-m"
                                    key={i}
                                >
                                    <NavLink
                                        className={({isActive, isPending}) => isActive
                                            ? "active-m"
                                            : isPending
                                                ? "pending"
                                                : ""}
                                        to={item.to}
                                        onClick={e=>showMobileMenu(false)}
                                    >{item.title}</NavLink>
                                </li>)}
                        </ul>
                    </div>
                    {/*<div className="quick-link">
                        <ul>
                            <li><i className="fab fa-whatsapp"></i> <span> 180-250-9625 </span></li>
                            <li><i className="bi bi-envelope"></i> <span> example@gmail.com</span></li>
                        </ul>
                    </div>
                    <ul className="side-media">
                        <li><a href="#"> <i className="fab fa-facebook-f"></i> </a></li>
                        <li><a href="#"> <i className="fab fa-twitter"></i> </a></li>
                        <li><a href="#"> <i className="fab fa-google-plus-g"></i> </a></li>
                        <li><a href="#"> <i className="fab fa-instagram"></i> </a></li>
                    </ul>*/}
                </div>
            </div>
        </div>
    );
}
