import React, {useEffect, useRef} from "react";

export default function ChessTable(props)
{
    const {teamList, gameList, final, getRef, listStart} = props;
    const sfx = final?'Final':'';

    const exportRef = useRef();
    useEffect(()=>{
        getRef(exportRef.current);
    })

    let num = listStart;

    const jsx = teamList.map((item,i)=>{

      return (<tr key={i} className="">
          <td key={`a${i}`} className="col-num">{num++}</td>
          <td key={`b${i}`} className="text-start">{item.name}</td>
          {
              teamList.map((n,i)=> {
                  const ball = "⚽️";
                  const rival = teamList[i];
                  const game = gameList.find(n=>{
                      const gameDate = new Date(n.date);
                      if(gameDate.getTime() > Date.now()) return false;
                      return (n.t1ID===item.id&&n.t2ID===rival.id) || (n.t2ID===item.id&&n.t1ID===rival.id)
                  })
                  let content = '';
                  if(item.id===rival.id) content = ball
                  if(checkGame(game)){
                      content = game.t1ID ===item.id?
                          `${game.t1Goals}-${game.t2Goals}`:
                          `${game.t2Goals}-${game.t1Goals}`
                  }
                  return <td key={i} className="text-nowrap">{content}</td>
              } )
          }

          <td className="">{item['gamesCount'+sfx]}</td>
          <td className="">{item['wins'+sfx]}</td>
          <td className="">{item['loses'+sfx]}</td>
          <td className="">{item['draw'+sfx]}</td>
          <td className="text-nowrap col-balls">{item['goals'+sfx]} - {item['misses'+sfx]}</td>
          <td className="">{item['points'+sfx]}</td>
      </tr>)
    })
    return (
    <div className="chess-table__wrap" ref={exportRef}>
        <table className="table table-light table-striped table-hover table-bordered text-center">
            <thead className="table-dark">
                <tr className="">
                    <th className="col-num">#</th>
                    <th className="text-start">Команда</th>
                    {teamList.map((n,i)=><th key={i} className="dgts">{++i}</th> )}
                    <th className="">И</th>
                    <th className="">В</th>
                    <th className="">П</th>
                    <th className="">Н</th>
                    <th className=" col-balls">М</th>
                    <th className="">О</th>
                </tr>
            </thead>
            <tbody>
                {jsx}
            </tbody>
        </table>
    </div>)
}

function checkGame(game)
{
    if(!game) return false;
    if(game&&(game.t1Goals===undefined||game.t2Goals===undefined)) return false;
    if(game&&(game.t1Goals===null||game.t2Goals===null)) return false;
    return true;
}