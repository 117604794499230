import React from "react";
import {Link, NavLink} from "react-router-dom";

export default function Header(props)
{
    const {logo, nav, showMobileMenu} = props;
    return (
        <header className="header">
            <nav className="navbar navbar-expand-lg navbar-light ">
                <div className="container align-items-center">
                    {logo&&<Link to="/" className="header-logo">
                        <img className="header-logo" src={logo} alt="logo"/>
                    </Link>}
                    <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasRightmobile"
                            onClick={e=>{
                                showMobileMenu(true)
                            }}
                    >
                        <i className="fas fa-bars"></i>
                    </button>
                    <nav className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                            {nav&&nav.map((item, i)=>
                                <li key={i}  className="nav-item">
                                    <NavLink to={item.to} className={({isActive, isPending}) => isActive
                                        ? "nav-link active"
                                        : isPending
                                        ? "nav-link pending"
                                        : "nav-link"}>{item.title}</NavLink>
                                </li>
                            )}
                        </ul>
                    </nav>
                </div>
            </nav>

        </header>
    )
}