export const DB = {
    groups:[2015,2016,2017],
    year:[2015,2016,2017],
    games: {
        /** Row Template:
        {date: '2023-04-01 09:00:00', t1ID:16,t2ID:13,t1Goals: 0, t2Goals: 0, field:1},
        */
        2015: [
            {date: '2023-04-08 09:00:00', t1ID: 1,t2ID: 2,t1Goals: 1, t2Goals: 0, field:1},//Сормово	Volga Kids
            // {date: '2023-04-08 10:40:00', t1ID: 3,t2ID: 2,t1Goals: 3, t2Goals: 6, field:2},//Сормово-2	Volga Kids

            {date: '2023-04-15 10:40:00', t1ID: 8,t2ID: 4,t1Goals: 3, t2Goals: 8, field:3},//Soccerball	Алмаз 2016
            {date: '2023-04-15 11:30:00', t1ID: 7,t2ID:12,t1Goals:15, t2Goals: 1, field:4},//ФШМ	ФШМ - СШОР-8-Строитель
            {date: '2023-04-15 13:10:00', t1ID: 7,t2ID: 6,t1Goals: 8, t2Goals: 3, field:4},//ФШМ	Лилифут 2016
            {date: '2023-04-15 12:20:00', t1ID:10,t2ID: 5,t1Goals: 2, t2Goals: 2, field:4},//Алмаз	Энергия
            {date: '2023-04-15 12:20:00', t1ID: 8,t2ID:16,t1Goals: 5, t2Goals: 4, field:3},//Soccerball	Волжский берег
            {date: '2023-04-15 13:10:00', t1ID:13,t2ID: 9,t1Goals: 0, t2Goals: 1, field:3},//СШОР-8-Строитель-2	777
            {date: '2023-04-15 14:00:00', t1ID:16,t2ID:11,t1Goals: 2, t2Goals: 7, field:3},//Волжский берег	СШОР-8

            // {date: '2023-04-15 11:30:00', t1ID: 7,t2ID:15,t1Goals: 0, t2Goals: 0, field:4},//ФШМ	Импульс
            // {date: '2023-04-15 12:20:00', t1ID: 8,t2ID: 9,t1Goals: 0, t2Goals: 0, field:3},//Soccerball	777
            // {date: '2023-04-15 09:50:00', t1ID:12,t2ID:15,t1Goals: 0, t2Goals: 0, field:4},//СШОР-8-Строитель	Импульс

            {date: '2023-04-22 12:20:00', t1ID:13,t2ID: 8,t1Goals: 2, t2Goals: 6, field:2},// СШОР-8-Строитель-2	Soccerball
            {date: '2023-04-22 13:10:00', t1ID:11,t2ID: 2,t1Goals: 6, t2Goals: 0, field:2},// СШОР-8	Volga kids
            {date: '2023-04-22 14:50:00', t1ID:16,t2ID: 2,t1Goals: 5, t2Goals: 3, field:2},// Волжский берег	Volga kids
            {date: '2023-04-22 09:50:00', t1ID:12,t2ID: 3,t1Goals: 8, t2Goals: 0, field:3},// СШОР-8-Строитель	Сормово-2
            {date: '2023-04-22 13:10:00', t1ID:15,t2ID:10,t1Goals: 4, t2Goals:10, field:3},// Импульс	Алмаз
            {date: '2023-04-22 14:50:00', t1ID:15,t2ID: 5,t1Goals: 6, t2Goals: 3, field:3},// Импульс	Энергия
            {date: '2023-04-22 11:30:00', t1ID:10,t2ID: 3,t1Goals:15, t2Goals: 1, field:4},// Алмаз	Сормово-2
            {date: '2023-04-22 12:20:00', t1ID: 4,t2ID: 1,t1Goals: 3, t2Goals: 9, field:4},// Алмаз 2016	Сормово
            {date: '2023-04-22 13:10:00', t1ID: 6,t2ID: 5,t1Goals: 2, t2Goals: 4, field:4},// Лилифут 2016	Энергия
            {date: '2023-04-22 14:00:00', t1ID: 1,t2ID: 8,t1Goals: 7, t2Goals: 1, field:4},// Сормово	Soccerball
            {date: '2023-04-22 15:40:00', t1ID:16,t2ID:13,t1Goals: 8, t2Goals: 0, field:1},//Волжский берег	СШОР-8-Строитель-2

            {date: '2023-04-29 09:00:00', t1ID: 5,t2ID:14,t1Goals: 4, t2Goals: 2, field:1},//Энергия - Пионер 4-2
            {date: '2023-04-29 10:40:00', t1ID: 5,t2ID: 7,t1Goals: 2, t2Goals: 8, field:1},//Энергия - ФШМ 2-8
            {date: '2023-04-29 11:30:00', t1ID:14,t2ID:12,t1Goals: 5, t2Goals: 1, field:1},//Пионер - СШОР-8-Строитель 5-1
            {date: '2023-04-29 13:10:00', t1ID: 7,t2ID: 3,t1Goals:16, t2Goals: 0, field:2},//ФШМ - Сормово-2 16-0
            {date: '2023-04-29 11:30:00', t1ID: 4,t2ID:11,t1Goals: 3, t2Goals: 7, field:3},//Алмаз 2016 - СШОР-8 3-7
            {date: '2023-04-29 12:20:00', t1ID:10,t2ID:12,t1Goals: 7, t2Goals: 3, field:3},//Алмаз - СШОР-8-Строитель 7-3
            {date: '2023-04-29 14:00:00', t1ID: 1,t2ID:16,t1Goals: 7, t2Goals: 1, field:3},//Сормово - Волжский берег 7-1
            {date: '2023-04-29 14:50:00', t1ID: 1,t2ID:13,t1Goals: 7, t2Goals: 0, field:4},//Сормово - СШОР-8-Строитель-2 7-0
            {date: '2023-04-29 09:00:00', t1ID:11,t2ID:13,t1Goals: 9, t2Goals: 1, field:1},//СШОР-8    СШОР-8-Строитель-2

            {date: '2023-05-06 13:10:00', t1ID:10,t2ID:14,t1Goals: 6, t2Goals: 1, field:1},//Алмаз - Пионер 6-1
            {date: '2023-05-06 13:10:00', t1ID: 4,t2ID: 9,t1Goals:24, t2Goals: 0, field:1},//Алмаз 2016 - 777 24-0
            {date: '2023-05-06 13:10:00', t1ID:14,t2ID: 3,t1Goals:15, t2Goals: 1, field:1},//Пионер - Сормово-2 15-1
            {date: '2023-05-06 13:10:00', t1ID: 4,t2ID:13,t1Goals:10, t2Goals: 0, field:1},//Алмаз 2016 - СШОР-8-Строитель-2 10-0
            {date: '2023-05-06 13:10:00', t1ID: 6,t2ID:12,t1Goals: 9, t2Goals: 1, field:1},//Лилифут 2016 - СШОР-8-Строитель 9-1
            {date: '2023-05-06 13:10:00', t1ID: 6,t2ID: 3,t1Goals:14, t2Goals: 1, field:1},//Лилифут 2016 Сормово-2 14-1
            {date: '2023-05-06 13:10:00', t1ID: 8,t2ID: 9,t1Goals: 6, t2Goals: 0, field:1},//Soccerball - 777 6-0
            {date: '2023-05-06 13:10:00', t1ID:11,t2ID: 8,t1Goals: 9, t2Goals: 0, field:1},//СШОР-8 - Soccerball 9-0
            {date: '2023-05-06 13:10:00', t1ID: 1,t2ID: 9,t1Goals: 9, t2Goals: 2, field:1},//Сормово - 777 9-2

            {date: '2023-05-13 12:20:00', t1ID:11,t2ID: 1,t1Goals: 3, t2Goals: 4, field:1},//12.20 СШОР-8	Сормово
            {date: '2023-05-13 09:00:00', t1ID: 9,t2ID: 2,t1Goals: 2, t2Goals: 1, field:3},//14.50 777	VOLGA KIDS
            {date: '2023-05-13 09:50:00', t1ID: 9,t2ID:16,t1Goals: 3, t2Goals: 6, field:3},//15.40 777	Волжский берег
            {date: '2023-05-13 09:00:00', t1ID:14,t2ID: 6,t1Goals: 6, t2Goals: 3, field:4},//09.00 Пионер	Лилифут 2016
            {date: '2023-05-13 09:50:00', t1ID: 5,t2ID: 3,t1Goals: 8, t2Goals: 0, field:4},//09.50 Энергия	Сормово-2
            {date: '2023-05-13 11:30:00', t1ID: 7,t2ID:14,t1Goals: 7, t2Goals: 1, field:4},//11.30 ФШМ	Пионер

            {date: '2023-05-20 09:00:00', t1ID:15,t2ID:14,t1Goals: 3, t2Goals: 1, field:1},// Импульс Пионер
            {date: '2023-05-20 09:50:00', t1ID:12,t2ID: 5,t1Goals: 2, t2Goals: 5, field:1},// СШОР-8-Строитель Энергия
            {date: '2023-05-20 09:00:00', t1ID:13,t2ID: 3,t1Goals: 4, t2Goals: 5, field:3},// СШОР-8-Строитель-2 Сормово-2
            {date: '2023-05-20 10:40:00', t1ID: 3,t2ID:15,t1Goals: 3, t2Goals:20, field:3},// Сормово-2 Импульс

            {date: '2023-05-27 09:50:00', t1ID: 8,t2ID: 2,t1Goals: 3, t2Goals: 4, field:1},//Soccerball - Volga-Kids
            {date: '2023-05-27 14:00:00', t1ID: 4,t2ID:16,t1Goals: 2, t2Goals: 2, field:1},//Алмаз 2016 - Волжский берег
            {date: '2023-05-27 14:50:00', t1ID:10,t2ID: 6,t1Goals: 4, t2Goals: 1, field:1},//Алмаз - Лилифут 2016
            {date: '2023-05-27 13:10:00', t1ID: 2,t2ID:13,t1Goals: 9, t2Goals: 1, field:2},//Volga-Kids - СШОР-8-Строитель-2
            {date: '2023-05-27 14:50:00', t1ID: 9,t2ID: 3,t1Goals: 2, t2Goals: 6, field:2},//777 - Сормово-2
            {date: '2023-05-27 14:00:00', t1ID: 9,t2ID:11,t1Goals: 0, t2Goals:10, field:3},//777 - СШОР-8
            {date: '2023-05-27 14:00:00', t1ID: 6,t2ID:15,t1Goals: 3, t2Goals: 3, field:4},//Лилифут 2016 - Импульс
            {date: '2023-05-27 14:50:00', t1ID:12,t2ID:15,t1Goals: 1, t2Goals: 2, field:4},//СШОР-8-Строитель - Импульс
            // {date: '2023-05-13 15:40:00', t1ID: 8,t2ID: 2,t1Goals: null, t2Goals: null, field:3},//15.40 #3 Soccerball	VOLGA KIDS
            {date: '2023-06-03 09:50:00', t1ID: 3,t2ID: 2,t1Goals: 0, t2Goals:11, field:3},//Сормово-2	Volga Kids
            {date: '2023-06-03 10:40:00', t1ID: 1,t2ID: 5,t1Goals: 2, t2Goals: 1, field:4},//Сормово - Энергия
            {date: '2023-06-03 12:20:00', t1ID: 4,t2ID: 2,t1Goals: 2, t2Goals: 2, field:2},//Алмаз 2016 - Volga-Kids
            {date: '2023-06-03 13:10:00', t1ID:10,t2ID: 7,t1Goals: 3, t2Goals: 3, field:3},//Алмаз - ФШМ
            {date: '2023-06-03 14:00:00', t1ID:15,t2ID:16,t1Goals: 3, t2Goals: 1, field:3},//Импульс - Волжский берег
            {date: '2023-06-03 14:50:00', t1ID:10,t2ID:16,t1Goals: 6, t2Goals: 0, field:3},//Алмаз - Волжский берег
            {date: '2023-06-03 09:00:00', t1ID:11,t2ID: 7,t1Goals: 3, t2Goals: 7, field:4},//СШОР-8 - Энергия
            {date: '2023-06-03 09:50:00', t1ID: 9,t2ID:12,t1Goals: 2, t2Goals: 1, field:4},//777 - СШОР-8-Строитель
            {date: '2023-06-03 11:30:00', t1ID: 2,t2ID:12,t1Goals: 5, t2Goals: 2, field:4},//Volga kids - СШОР-8-Строитель 5-2
            {date: '2023-06-03 11:30:00', t1ID: 7,t2ID:15,t1Goals: 9, t2Goals: 1, field:3},// ФШМ - Импульс 9-1
        ],
        2016:[
            {date: '2023-04-08 11:30:00', t1ID: 2,t2ID: 1,t1Goals: 3, t2Goals: 4, field:2},//СШОР-8-Смена	СШОР-8
            {date: '2023-04-08 12:20:00', t1ID: 7,t2ID: 2,t1Goals: 0, t2Goals: 9, field:2},//Kids-зелёные	СШОР-8-Смена
            {date: '2023-04-08 13:10:00', t1ID: 1,t2ID: 7,t1Goals: 8, t2Goals: 3, field:2},//СШОР-8 Kids-зелёные
            {date: '2023-04-08 08:45:00', t1ID:15,t2ID:13,t1Goals: 0, t2Goals:10, field:2},//СШОР-8-Юность	Радий
            {date: '2023-04-08 09:50:00', t1ID:21,t2ID:12,t1Goals: 0, t2Goals: 8, field:2},//Спарта	Лилифут
            {date: '2023-04-08 14:00:00', t1ID:14,t2ID:16,t1Goals: 2, t2Goals: 2, field:2},//Kids-синие   Soccerball-2

            {date: '2023-04-15 09:00:00', t1ID:16,t2ID:19,t1Goals: 3, t2Goals: 3, field:1},//	Soccerball-2	Алмаз-2
            // {date: '2023-04-15 09:50:00', t1ID:15,t2ID:16,t1Goals: 0, t2Goals: 0, field:1},//	СШОР-8-Юность	Soccerball-2
            {date: '2023-04-15 10:40:00', t1ID:15,t2ID:21,t1Goals: 0, t2Goals:10, field:1},//	СШОР-8-Юность	Спарта
            {date: '2023-04-15 11:30:00', t1ID:15,t2ID:20,t1Goals: 1, t2Goals:15, field:1},//	СШОР-8-Юность	ФШМ
            {date: '2023-04-15 12:20:00', t1ID:20,t2ID:14,t1Goals:11, t2Goals: 0, field:1},//	ФШМ	Kids - синие
            {date: '2023-04-15 13:10:00', t1ID:11,t2ID: 7,t1Goals: 8, t2Goals: 2, field:1},//	Олимп	Kids - зелёные
            {date: '2023-04-15 14:00:00', t1ID:13,t2ID:12,t1Goals: 1, t2Goals: 6, field:1},//	Радий	Лилифут
            // {date: '2023-04-15 09:00:00', t1ID: 2,t2ID: 5,t1Goals: 0, t2Goals: 4, field:3},//	СШОР-8-Смена	Soccerball
            {date: '2023-04-15 09:50:00', t1ID: 3,t2ID: 1,t1Goals: 3, t2Goals: 8, field:3},//	Football Masters	СШОР-8
            {date: '2023-04-15 11:30:00', t1ID: 3,t2ID: 4,t1Goals: 9, t2Goals: 2, field:3},//	Football Masters	Volga kids
            {date: '2023-04-15 10:40:00', t1ID: 5,t2ID: 2,t1Goals: 4, t2Goals: 0, field:4},//	Soccerball	СШОР-8-Смена
            {date: '2023-04-15 14:00:00', t1ID: 9,t2ID:11,t1Goals: 3, t2Goals: 7, field:4},//	ФШМ Дубль	Олимп

            {date: '2023-04-22 09:00:00', t1ID: 4,t2ID: 7,t1Goals: 2, t2Goals: 5, field:2},//	Volga kids	Kids - зеленые
            {date: '2023-04-22 09:50:00', t1ID:19,t2ID:21,t1Goals: 0, t2Goals: 7, field:2},//	Алмаз-2	Спарта
            {date: '2023-04-22 10:40:00', t1ID: 3,t2ID: 2,t1Goals: 4, t2Goals: 0, field:2},//	Football Masters	СШОР-8-Смена
            {date: '2023-04-22 11:30:00', t1ID:19,t2ID:15,t1Goals: 3, t2Goals: 3, field:2},//	Алмаз-2	СШОР-8-Юность
            {date: '2023-04-22 14:00:00', t1ID:14,t2ID:18,t1Goals: 1, t2Goals:16, field:2},//	Kids - синие 	Академия
            {date: '2023-04-22 15:40:00', t1ID:13,t2ID:18,t1Goals: 3, t2Goals: 2, field:2},//	Радий	Академия
            {date: '2023-04-22 10:40:00', t1ID: 1,t2ID: 4,t1Goals: 7, t2Goals: 0, field:3},//	СШОР-8	Volga kids
            {date: '2023-04-22 12:20:00', t1ID:22,t2ID:14,t1Goals:10, t2Goals: 0, field:3},//	Импульс	Kids - синие
            {date: '2023-04-22 14:00:00', t1ID:22,t2ID:13,t1Goals: 5, t2Goals: 1, field:3},//	Импульс	Радий
            {date: '2023-04-22 10:40:00', t1ID: 7,t2ID: 6,t1Goals: 2, t2Goals:10, field:4},//	Kids - зеленые	Алмаз
            {date: '2023-04-22 14:50:00', t1ID:12,t2ID:16,t1Goals:17, t2Goals: 0, field:4},//	Лилифут	Soccerball-2

            {date: '2023-04-29 09:50:00', t1ID:18,t2ID:19,t1Goals: 6, t2Goals: 1, field:1},//Академия - Алмаз-2 6-1
            {date: '2023-04-29 09:00:00', t1ID: 6,t2ID: 2,t1Goals: 9, t2Goals: 0, field:1},//Алмаз - СШОР-8-Смена 9-0
            {date: '2023-04-29 09:50:00', t1ID:21,t2ID:17,t1Goals: 9, t2Goals: 5, field:1},//Спарта - SMILE FOOTBALL - Gray 9-5
            {date: '2023-04-29 10:40:00', t1ID:11,t2ID: 5,t1Goals: 3, t2Goals: 2, field:1},//Олимп - Soccerball 3-2
            {date: '2023-04-29 11:30:00', t1ID:21,t2ID:22,t1Goals: 2, t2Goals: 3, field:1},//Спарта - Импульс 2-3
            {date: '2023-04-29 12:20:00', t1ID:20,t2ID:22,t1Goals: 6, t2Goals: 2, field:1},//ФШМ - Импульс 6-2
            {date: '2023-04-29 13:10:00', t1ID:20,t2ID:13,t1Goals: 4, t2Goals: 4, field:1},//ФШМ - Радий 4-4
            {date: '2023-04-29 14:50:00', t1ID:14,t2ID:13,t1Goals: 0, t2Goals:11, field:1},//Kids - синие - Радий 0-11
            {date: '2023-04-29 10:40:00', t1ID: 6,t2ID: 5,t1Goals: 3, t2Goals: 3, field:1},//Алмаз - Soccerball 3-3
            {date: '2023-04-29 09:00:00', t1ID:18,t2ID:16,t1Goals:13, t2Goals: 0, field:1},//Академия - Soccerball-2 13-0
            {date: '2023-04-29 09:50:00', t1ID: 1,t2ID: 9,t1Goals:11, t2Goals: 1, field:1},//СШОР-8 - ФШМ-Дубль 11-1
            {date: '2023-04-29 10:40:00', t1ID:15,t2ID:17,t1Goals: 1, t2Goals: 9, field:1},//СШОР-8-Юность - SMILE FOOTBALL - Gray 1-9
            {date: '2023-04-29 11:30:00', t1ID: 9,t2ID: 8,t1Goals: 2, t2Goals:12, field:1},//ФШМ-Дубль - SMILE FOOTBALL - Blue 2-12
            {date: '2023-04-29 12:20:00', t1ID:11,t2ID: 4,t1Goals:11, t2Goals: 0, field:1},//Олимп - Volga kids 11-0
            {date: '2023-04-29 14:00:00', t1ID: 4,t2ID: 8,t1Goals: 3, t2Goals: 9, field:1},//Volga kids - SMILE FOOTBALL - Blue 3-9

            {date: '2023-05-06 09:00:00', t1ID: 1,t2ID: 5,t1Goals: 0, t2Goals: 1, field:1},//СШОР-8 - Soccerball 0-1
            {date: '2023-05-06 09:00:00', t1ID: 2,t2ID:11,t1Goals: 3, t2Goals: 6, field:1},//СШОР-8-Смена - Олимп 3-6
            {date: '2023-05-06 09:00:00', t1ID: 5,t2ID: 8,t1Goals: 3, t2Goals: 4, field:1},//Soccerball - SMILE FOOTBALL-Blue 3-4
            {date: '2023-05-06 09:00:00', t1ID:11,t2ID: 6,t1Goals: 7, t2Goals: 3, field:1},//Олимп - Алмаз 7-3
            {date: '2023-05-06 09:00:00', t1ID:21,t2ID:16,t1Goals: 8, t2Goals: 0, field:1},//Спарта - Soccerball-2 8-0
            {date: '2023-05-06 09:00:00', t1ID: 3,t2ID: 8,t1Goals: 2, t2Goals: 8, field:1},//FOOTBALL MASTERS - SMILE FOOTBALL - Blue 2-8
            {date: '2023-05-06 09:00:00', t1ID:12,t2ID:19,t1Goals: 7, t2Goals: 0, field:1},//Лилифут - Алмаз-2 7-0
            {date: '2023-05-06 09:00:00', t1ID:18,t2ID:15,t1Goals: 6, t2Goals: 2, field:1},//Академия - СШОР-8-Юность 6-2
            {date: '2023-05-06 09:00:00', t1ID:18,t2ID:17,t1Goals: 7, t2Goals: 4, field:1},//Академия - SMILE FOOTBALL - Gray 7-4
            {date: '2023-05-06 09:00:00', t1ID:21,t2ID:13,t1Goals: 2, t2Goals: 6, field:1},//Спарта - Радий 2-6
            {date: '2023-05-06 09:00:00', t1ID:16,t2ID:17,t1Goals: 0, t2Goals:15, field:1},//Soccerball-2 - SMILE FOOTBALL - Gray 0-15

            {date: '2023-05-13 10:40:00', t1ID: 4,t2ID: 5,t1Goals: 0, t2Goals:13, field:2},//10.40 #2 VOLGA KIDS	Soccerball
            {date: '2023-05-13 12:20:00', t1ID: 4,t2ID: 9,t1Goals: 2, t2Goals: 4, field:2},//12.20 #2 VOLGA KIDS	ФШМ-дубль
            {date: '2023-05-13 13:10:00', t1ID: 3,t2ID: 5,t1Goals: 3, t2Goals: 3, field:2},//13.10 #2 FOOTBALL MASTERS	Soccerball
            {date: '2023-05-13 14:00:00', t1ID:16,t2ID:13,t1Goals: 0, t2Goals:12, field:2},//14.00 #2 Soccerball-2	Радий
            {date: '2023-05-13 14:50:00', t1ID:11,t2ID: 3,t1Goals: 8, t2Goals: 2, field:2},//14.50 #2 Олимп	FOOTBALL MASTERS
            {date: '2023-05-13 15:40:00', t1ID:13,t2ID:17,t1Goals: 6, t2Goals: 0, field:2},//15.40 #2 Радий	SMILE FOOTBALL - Gray
            {date: '2023-05-13 10:40:00', t1ID:14,t2ID:12,t1Goals: 0, t2Goals:12, field:3},//10.40 #3 KIDS - синие	Лилифут
            {date: '2023-05-13 11:40:00', t1ID:12,t2ID:18,t1Goals: 7, t2Goals: 4, field:3},//11.30 #3 Лилифут	Академия
            {date: '2023-05-13 12:20:00', t1ID:21,t2ID:14,t1Goals: 5, t2Goals: 0, field:3},//12.20 #3 Спарта	KIDS - синие
            {date: '2023-05-13 13:10:00', t1ID: 7,t2ID: 8,t1Goals: 1, t2Goals:14, field:3},//13.10 #3 KIDS - зеленые	SMILE FOOTBALL – Blue
            {date: '2023-05-13 14:00:00', t1ID: 7,t2ID: 9,t1Goals: 2, t2Goals: 5, field:3},//14.00 #3 KIDS - зеленые	ФШМ-дубль
            {date: '2023-05-13 14:50:00', t1ID:20,t2ID:21,t1Goals: 8, t2Goals: 3, field:3},//14.50 #3 ФШМ	Спарта
            {date: '2023-05-13 12:20:00', t1ID:22,t2ID:15,t1Goals: 3, t2Goals: 0, field:4},//12.20 #4 Импульс	СШОР-8-Юность
            {date: '2023-05-13 13:10:00', t1ID: 1,t2ID:11,t1Goals: 2, t2Goals: 5, field:4},//13.10 #4 СШОР-8	Олимп
            {date: '2023-05-13 14:00:00', t1ID:17,t2ID:22,t1Goals: 4, t2Goals: 4, field:4},//14.00 #4 SMILE FOOTBALL - Gray	Импульс
            {date: '2023-05-13 14:50:00', t1ID: 2,t2ID: 8,t1Goals: 4, t2Goals: 9, field:4},//14.50 #4 СШОР-8-Смена	SMILE FOOTBALL – Blue
            {date: '2023-05-13 15:40:00', t1ID:16,t2ID:20,t1Goals: 0, t2Goals:17, field:4},//15.40 #4 Soccerball-2	ФШМ

            {date: '2023-05-20 10:40:00', t1ID:18,t2ID:21,t1Goals: 6, t2Goals: 3, field:1},// Академия Спарта
            {date: '2023-05-20 13:00:00', t1ID: 3,t2ID: 9,t1Goals: 8, t2Goals: 3, field:1},// Football Masters ФШМ-Дубль
            {date: '2023-05-20 09:00:00', t1ID:12,t2ID:15,t1Goals:15, t2Goals: 1, field:2},// Лилифут СШОР-8-Юность
            {date: '2023-05-20 09:50:00', t1ID:20,t2ID:18,t1Goals: 1, t2Goals: 5, field:2},// ФШМ Академия
            {date: '2023-05-20 11:30:00', t1ID:12,t2ID:20,t1Goals: 7, t2Goals: 0, field:2},// Лилифут ФШМ
            {date: '2023-05-20 12:20:00', t1ID:19,t2ID:13,t1Goals: 2, t2Goals: 5, field:2},// Алмаз-2 Радий
            {date: '2023-05-20 09:50:00', t1ID: 4,t2ID: 2,t1Goals: 1, t2Goals:10, field:3},// Volga kids СШОР-8-Смена
            {date: '2023-05-20 14:50:00', t1ID: 6,t2ID: 4,t1Goals: 5, t2Goals: 0, field:2},//Алмаз - Volga kids

            {date: '2023-05-27 12:20:00', t1ID: 7,t2ID: 5,t1Goals: 0, t2Goals: 5, field:2},//Kids-зелёные - Soccerball
            {date: '2023-05-27 14:00:00', t1ID: 7,t2ID: 3,t1Goals: 0, t2Goals: 5, field:2},//Kids-зелёные - Football Masters
            {date: '2023-05-27 09:00:00', t1ID:15,t2ID:14,t1Goals: 5, t2Goals: 1, field:3},//СШОР-8-Юность - Kids-синие
            {date: '2023-05-27 09:50:00', t1ID: 2,t2ID: 9,t1Goals: 5, t2Goals: 4, field:3},//СШОР-8-Смена - ФШМ-Дубль
            {date: '2023-05-27 10:40:00', t1ID: 1,t2ID: 6,t1Goals: 5, t2Goals: 3, field:3},//СШОР-8 - Алмаз
            {date: '2023-05-27 11:30:00', t1ID:15,t2ID:16,t1Goals: 6, t2Goals: 1, field:3},//СШОР-8-Юность - Soccerball-2
            {date: '2023-05-27 12:20:00', t1ID: 6,t2ID: 3,t1Goals:11, t2Goals: 3, field:3},//Алмаз - Football Masters
            {date: '2023-05-27 13:10:00', t1ID:11,t2ID:18,t1Goals: 8, t2Goals: 2, field:3},//Олимп - Академия
            {date: '2023-05-27 14:50:00', t1ID:18,t2ID:22,t1Goals:16, t2Goals: 0, field:3},//Академия - Импульс
            {date: '2023-05-27 09:00:00', t1ID:19,t2ID:20,t1Goals: 0, t2Goals:11, field:4},//Алмаз-2 - ФШМ
            {date: '2023-05-27 09:50:00', t1ID:19,t2ID:14,t1Goals:14, t2Goals: 1, field:4},//Алмаз-2 - Kids-синие
            {date: '2023-05-27 10:40:00', t1ID: 9,t2ID: 5,t1Goals: 2, t2Goals:10, field:4},//ФШМ-Дубль - Soccerball
            {date: '2023-05-27 11:30:00', t1ID:20,t2ID:11,t1Goals: 6, t2Goals: 8, field:4},//ФШМ - Олимп
            {date: '2023-05-27 12:20:00', t1ID:12,t2ID:22,t1Goals:12, t2Goals: 0, field:4},//Лилифут - Импульс
            {date: '2023-05-27 13:10:00', t1ID:22,t2ID:16,t1Goals:10, t2Goals: 2, field:4},//Импульс - Soccerball-2

            {date: '2023-06-03 09:00:00', t1ID: 8,t2ID:11,t1Goals: 1, t2Goals: 7, field:1},//SMILE FOOTBALL-Blue - Олимп
            {date: '2023-06-03 09:50:00', t1ID: 7,t2ID:19,t1Goals: 1, t2Goals: 3, field:1},//Ф Kids-зеленые - Алмаз-2 1-3
            {date: '2023-06-03 10:40:00', t1ID:13,t2ID:11,t1Goals: 7, t2Goals: 1, field:1},//Ф Радий - Олимп
            {date: '2023-06-03 11:30:00', t1ID:16,t2ID: 7,t1Goals: 0, t2Goals: 9, field:1},//Ф Soccerball-2 - Kids - зеленые 0-9
            {date: '2023-06-03 12:20:00', t1ID: 1,t2ID: 8,t1Goals:10, t2Goals: 2, field:1},//СШОР-8 - SMILE FOOTBALL-Blue 10-2
            {date: '2023-06-03 13:10:00', t1ID:22,t2ID:19,t1Goals: 3, t2Goals: 1, field:1},//Импульс - Алмаз-2 3-1
            {date: '2023-06-03 14:00:00', t1ID: 2,t2ID:21,t1Goals: 3, t2Goals: 4, field:1},//Ф СШОР-8-Смена - Спарта 3-4
            {date: '2023-06-03 14:50:00', t1ID: 1,t2ID:22,t1Goals: 6, t2Goals: 0, field:1},//Ф СШОР-8 - Импульс 6-0
            {date: '2023-06-03 09:00:00', t1ID: 6,t2ID: 9,t1Goals:13, t2Goals: 3, field:2},//Алмаз - ФШМ-дубль 13-3
            {date: '2023-06-03 09:50:00', t1ID:20,t2ID:17,t1Goals: 8, t2Goals: 1, field:2},//ФШМ - SMILE FOOTBALL-Gray 8-1
            {date: '2023-06-03 10:40:00', t1ID: 8,t2ID: 6,t1Goals: 3, t2Goals: 9, field:2},//SMILE FOOTBALL Blue - Алмаз 3-9
            {date: '2023-06-03 11:30:00', t1ID:17,t2ID:19,t1Goals: 4, t2Goals: 2, field:2},//SMILE FOOTBALL-Gray Алмаз-2 4-2
            {date: '2023-06-03 13:10:00', t1ID:14,t2ID:17,t1Goals: 0, t2Goals: 9, field:2},//Kids - синие - SMILE FOOTBALL - Gray 0-9
            {date: '2023-06-03 14:50:00', t1ID:17,t2ID:12,t1Goals: 0, t2Goals: 9, field:2},//SMILE FOOTBALL-Gray - Лилифут 0-9
            {date: '2023-06-03 10:40:00', t1ID:15,t2ID: 9,t1Goals: 8, t2Goals: 3, field:3},//СШОР-8-Юность - ФШМ-дубль 8-3
            {date: '2023-06-03 12:20:00', t1ID:13,t2ID: 5,t1Goals: 8, t2Goals: 2, field:3},//Радий - Soccerball 8-2
            {date: '2023-06-03 12:20:00', t1ID: 9,t2ID:14,t1Goals: 3, t2Goals: 1, field:4},//Ф ФШМ-дубль - Kids - синие3-1
            {date: '2023-06-03 13:10:00', t1ID: 2,t2ID:16,t1Goals: 5, t2Goals: 2, field:4},//СШОР-8-Смена - Soccerball-2 5-2
        ],
        2017: [
            {date: '2023-04-08 09:50:00', t1ID: 2,t2ID:14,t1Goals: 2, t2Goals: 1, field:1},//СШОР-8-Смена	SMILE FOOTBALL-Blue
            {date: '2023-04-08 10:40:00', t1ID:13,t2ID:11,t1Goals: 4, t2Goals: 2, field:1},//SMILE FOOTBALL-Gray	СШОР-8-Чайка
            {date: '2023-04-08 11:30:00', t1ID:10,t2ID:14,t1Goals: 6, t2Goals: 1, field:1},//Академия	SMILE FOOTBALL-Blue
            {date: '2023-04-08 12:20:00', t1ID: 1,t2ID:13,t1Goals:10, t2Goals: 0, field:1},//СШОР-8	SMILE FOOTBALL-Gray
            {date: '2023-04-08 13:10:00', t1ID:10,t2ID:12,t1Goals:16, t2Goals: 0, field:1},//Академия	СШОР-8-Юность
            {date: '2023-04-08 14:00:00', t1ID: 1,t2ID: 6,t1Goals: 9, t2Goals: 0, field:1},//СШОР-8	Soccerball

            {date: '2023-04-15 09:00:00', t1ID:11,t2ID: 6,t1Goals: 3, t2Goals: 4, field:2},// СШОР-8-Чайка	Soccerball
            {date: '2023-04-15 09:50:00', t1ID: 7,t2ID:13,t1Goals: 3, t2Goals: 2, field:2},// Алмаз	SMILE FOOTBALL - Gray
            {date: '2023-04-15 10:40:00', t1ID:12,t2ID: 3,t1Goals: 0, t2Goals: 9, field:2},// СШОР-8-Юность	Volga kids
            {date: '2023-04-15 11:30:00', t1ID: 7,t2ID: 1,t1Goals: 0, t2Goals:10, field:2},// Алмаз	СШОР-8
            {date: '2023-04-15 12:20:00', t1ID:12,t2ID: 9,t1Goals: 0, t2Goals:24, field:2},// СШОР-8-Юность	Лилифут White
            {date: '2023-04-15 13:10:00', t1ID: 8,t2ID: 5,t1Goals: 8, t2Goals: 2, field:2},// Лилифут Black	Импульс
            {date: '2023-04-15 14:00:00', t1ID:10,t2ID: 9,t1Goals: 1, t2Goals: 9, field:2},// Академия	Лилифут White
            {date: '2023-04-15 14:50:00', t1ID: 2,t2ID:15,t1Goals: 1, t2Goals:15, field:2},// СШОР-8-Смена	Бриг

            {date: '2023-04-22 09:00:00', t1ID: 3,t2ID:10,t1Goals: 4, t2Goals: 2, field:1},// Volga kids	Академия
            {date: '2023-04-22 09:50:00', t1ID:16,t2ID: 2,t1Goals: 0, t2Goals: 3, field:1},// Kids	СШОР-8-Смена
            {date: '2023-04-22 10:40:00', t1ID:11,t2ID: 1,t1Goals: 0, t2Goals:10, field:1},// СШОР-8-Чайка 	СШОР-8
            {date: '2023-04-22 11:30:00', t1ID:12,t2ID:16,t1Goals: 5, t2Goals: 0, field:1},// СШОР-8-Юность 	Kids
            {date: '2023-04-22 12:20:00', t1ID: 7,t2ID: 6,t1Goals: 2, t2Goals: 7, field:1},// Алмаз	Soccerball
            {date: '2023-04-22 13:10:00', t1ID: 5,t2ID:11,t1Goals: 4, t2Goals: 1, field:1},// Импульс 	СШОР-8-Чайка
            {date: '2023-04-22 14:00:00', t1ID: 5,t2ID:13,t1Goals:10, t2Goals: 3, field:1},// Импульс	SMILE FOOTBALL - Gray
            {date: '2023-04-22 14:50:00', t1ID:14,t2ID:15,t1Goals: 4, t2Goals:13, field:1},// SMILE FOOTBALL - Blue	Бриг

            {date: '2023-04-29 09:00:00', t1ID: 9,t2ID:14,t1Goals: 8, t2Goals: 0, field:1},//Лилифут-White - SMILE FOOTBALL - Blue 8-0
            {date: '2023-04-29 09:00:00', t1ID: 9,t2ID:16,t1Goals:13, t2Goals: 0, field:1},//Лилифут-White - Kids 13-0
            {date: '2023-04-29 09:00:00', t1ID: 8,t2ID: 7,t1Goals:11, t2Goals: 0, field:1},//Лилифут -Black - Алмаз 11-0
            {date: '2023-04-29 09:00:00', t1ID: 5,t2ID: 7,t1Goals: 8, t2Goals: 0, field:1},//Импульс - Алмаз 8-0
            {date: '2023-04-29 09:00:00', t1ID:16,t2ID:15,t1Goals: 1, t2Goals:12, field:1},//Kids - Бриг 1-12
            {date: '2023-04-29 09:00:00', t1ID:13,t2ID: 6,t1Goals: 3, t2Goals: 5, field:1},//SMILE FOOTBALL - Gray - Soccerball 3-5
            {date: '2023-04-29 09:00:00', t1ID:14,t2ID: 3,t1Goals: 5, t2Goals: 4, field:1},//SMILE FOOTBALL - Blue - Volga kids 5-4

            {date: '2023-05-06 09:00:00', t1ID: 8,t2ID: 1,t1Goals: 5, t2Goals: 3, field:1},//Лилифут -Black - СШОР-8 5-3
            {date: '2023-05-06 09:00:00', t1ID:10,t2ID: 2,t1Goals: 4, t2Goals: 2, field:1},//Академия - СШОР-8-Смена 4-2
            {date: '2023-05-06 09:00:00', t1ID: 9,t2ID:15,t1Goals: 0, t2Goals: 5, field:1},//Лилифут-White - Бриг 0-5
            {date: '2023-05-06 09:00:00', t1ID:15,t2ID:12,t1Goals:22, t2Goals: 0, field:1},//Бриг - СШОР-8-Юность 22-0

            {date: '2023-05-13 09:00:00', t1ID: 8,t2ID:11,t1Goals:11, t2Goals: 0, field:1},//09.00	#1 Лилифут -Black	СШОР-8-Чайка
            {date: '2023-05-13 09:50:00', t1ID:16,t2ID:14,t1Goals: 1, t2Goals: 6, field:1},//09.50	#1 KIDS	SMILE FOOTBALL – Blue
            {date: '2023-05-13 10:40:00', t1ID: 8,t2ID:13,t1Goals:11, t2Goals: 0, field:1},//10.40	#1 Лилифут -Black	SMILE FOOTBALL - Gray
            {date: '2023-05-13 13:10:00', t1ID: 3,t2ID:15,t1Goals: 1, t2Goals: 8, field:1},//13.10	#1 VOLGA KIDS	Бриг
            {date: '2023-05-13 11:30:00', t1ID: 9,t2ID: 2,t1Goals: 9, t2Goals: 0, field:1},//11.30	#1 Лилифут - White	СШОР-8-Смена
            {date: '2023-05-13 09:00:00', t1ID: 5,t2ID: 6,t1Goals:12, t2Goals: 3, field:2},//09.00	#2 Импульс	Soccerball
            {date: '2023-05-13 09:50:00', t1ID: 2,t2ID:12,t1Goals: 7, t2Goals: 1, field:2},//09.50	#2 СШОР-8-Смена	СШОР-8-Юность
            {date: '2023-05-13 11:30:00', t1ID:16,t2ID: 3,t1Goals: 2, t2Goals: 4, field:2},//11.30	#2 KIDS	VOLGA KIDS
            {date: '2023-05-13 10:40:00', t1ID: 1,t2ID: 5,t1Goals: 7, t2Goals: 5, field:4},//10.40	#4 СШОР-8	Импульс

            {date: '2023-05-20 10:40:00', t1ID:16,t2ID:10,t1Goals: 0, t2Goals:11, field:2},// Kids Академия
            {date: '2023-05-20 13:10:00', t1ID:10,t2ID:15,t1Goals: 5, t2Goals:10, field:2},// Академия Бриг
            {date: '2023-05-20 09:00:00', t1ID:11,t2ID: 7,t1Goals: 3, t2Goals: 6, field:4},// СШОР-8-Чайка Алмаз
            {date: '2023-05-20 09:00:00', t1ID:12,t2ID:14,t1Goals: 0, t2Goals:14, field:4},// СШОР-8-Юность SMILE FOOTBALL-Blue
            {date: '2023-05-20 09:00:00', t1ID:11,t2ID:12,t1Goals: 9, t2Goals: 0, field:4},// СШОР-8-Чайка    СШОР-8-Юность

            {date: '2023-05-27 09:00:00', t1ID: 2,t2ID: 3,t1Goals: 2, t2Goals: 4, field:1},//СШОР-8-Смена - Volga kids
            {date: '2023-05-27 10:40:00', t1ID: 3,t2ID: 9,t1Goals: 2, t2Goals: 3, field:1},//Volga kids - Лилифут White
            {date: '2023-05-27 11:30:00', t1ID: 8,t2ID:10,t1Goals: 6, t2Goals: 1, field:1},//Лилифут Black - Академия
            {date: '2023-05-27 12:20:00', t1ID: 1,t2ID: 9,t1Goals: 7, t2Goals: 2, field:1},//СШОР-8 - Лилифут White
            {date: '2023-05-27 13:10:00', t1ID: 5,t2ID:10,t1Goals: 2, t2Goals: 2, field:1},//Импульс - Академия
            {date: '2023-05-27 09:00:00', t1ID: 8,t2ID: 6,t1Goals: 9, t2Goals: 1, field:2},//Лилифут Black - Soccerball
            {date: '2023-05-27 09:50:00', t1ID:12,t2ID: 6,t1Goals: 0, t2Goals:21, field:2},//СШОР-8-Юность - Soccerball
            {date: '2023-05-27 10:40:00', t1ID:16,t2ID:11,t1Goals: 3, t2Goals: 1, field:2},//Kids - СШОР-8-Чайка
            {date: '2023-05-27 11:30:00', t1ID:16,t2ID: 7,t1Goals: 1, t2Goals: 3, field:2},//Kids - Алмаз
            // {date: '2023-05-26 11:30:00', t1ID:16,t2ID: 7,t1Goals: null, t2Goals: null, field:2},//Kids - Алмаз
            {date: '2023-06-03 09:00:00', t1ID: 6,t2ID: 3,t1Goals: 9, t2Goals: 2, field:3},//Soccerball - Volga Kids 9-2
            {date: '2023-06-03 14:00:00', t1ID: 8,t2ID: 3,t1Goals: 3, t2Goals: 0, field:4},//Лилифут -Black - Volga kids 3-0
            {date: '2023-06-03 14:50:00', t1ID: 8,t2ID: 9,t1Goals: 3, t2Goals: 1, field:4},//Лилифут -Black - Лилифут - White 3-1
        ],
    },
    teams: {
        2015: {
            id1: {id: 1, name: 'Сормово',points: 0, sub:'a', fin: 'C'},
            id2: {id: 2, name: 'Volga-Kids',points: 0, sub:'a',fin: 'D'},
            id3: {id: 3, name: 'Сормово-2',points: 0, sub:'b',fin: 'D'},
            id4: {id: 4, name: 'Алмаз 2016',points: 0, sub:'a',fin: 'C'},
            id5: {id: 5, name: 'Энергия',points: 0, sub:'b',fin: 'C'},
            id6: {id: 6, name: 'Лилифут 2016',points: 0, sub:'b',fin: 'D'},
            id7: {id: 7, name: 'ФШМ (Бор)',points: 0, sub:'b',fin: 'C'},
            id8: {id: 8, name: 'Soccerball',points: 0, sub:'a',fin: 'D'},
            id9: {id: 9, name: '777',points: 0, sub:'a',fin: 'D'},
            id10:{id:10, name: 'Алмаз',points: 0, sub:'b',fin: 'C'},
            id11:{id:11, name: 'СШОР-8',points: 0, sub:'a',fin: 'C'},
            id12:{id:12, name: 'СШОР-8-Строитель',points: 0, sub:'b',fin: 'D'},
            id13:{id:13, name: 'СШОР-8-Строитель-2',points: 0, sub:'a',fin: 'D'},
            id14:{id:14, name: 'Пионер',points: 0, sub:'b',fin: 'D'},
            id15:{id:15, name: 'Импульс',points: 0, sub:'b',fin: 'C'},
            id16:{id:16, name: 'Волжский берег',points: 0, sub:'a',fin: 'C'}
        },
        2016: {
            id1: {id: '1',name:'СШОР-8', sub: 'A',fin: 'C'},
            id2: {id: '2',name:'СШОР-8-Смена', sub: 'A',fin: 'D'},
            id3: {id: '3',name:'Football Masters', sub: 'A',fin: 'D'},
            id4: {id: '4',name:'Volga kids', sub: 'A',fin: 'D'},
            id5: {id: '5',name:'Soccerball', sub: 'A',fin: 'C'},
            id6: {id: '6',name:'Алмаз', sub: 'A',fin: 'C'},
            id7: {id: '7',name:'Kids-зелёные', sub: 'A',fin: 'D'},
            id8: {id: '8',name:'Smile Football-Blue', sub: 'A',fin: 'C'},
            id9: {id: '9',name:'ФШМ-Дубль (Бор)', sub: 'A',fin: 'D'},
            // id10:{id:'10',name:'Спартанки (Богородск)', sub: 'A',fin: ''},
            id11:{id:'11',name:'Олимп', sub: 'A',fin: 'C'},
            id12:{id:'12',name:'Лилифут', sub: 'B',fin: 'C'},
            id13:{id:'13',name:'Радий', sub: 'B',fin: 'C'},
            id14:{id:'14',name:'Kids-синие', sub: 'B',fin: 'D'},
            id15:{id:'15',name:'СШОР-8-Юность', sub: 'B',fin: 'D'},
            id16:{id:'16',name:'Soccerball-2', sub: 'B',fin: 'D'},
            id17:{id:'17',name:'Smile Football-Gray', sub: 'B',fin: 'D'},
            id18:{id:'18',name:'Академия', sub: 'B',fin: 'C'},
            id19:{id:'19',name:'Алмаз-2', sub: 'B',fin: 'D'},
            id20:{id:'20',name:'ФШМ (Бор)', sub: 'B',fin: 'C'},
            id21:{id:'21',name:'Спарта (Ворсма)', sub: 'B',fin: 'D'},
            id22:{id:'22',name:'Импульс', sub: 'B',fin: 'C'},
        },
        // Kids-зелёные (Дзержинск)
        // Kids-синие (Дзержинск)
        // Smile Football-Gray (Дзержинск)
        // Smile Football-Blue (Дзержинск)
        // Пионер (Дзержинск)
        2017: {
            id1: {id:1,name:"СШОР-8",sub: "A",fin: 'C'},
            id2: {id:2,name:"СШОР-8-Смена", sub: 'B',fin: 'D'},
            id3: {id:3,name:"Volga kids", sub: 'B',fin: 'C'},
            // id4: {id:4,name:"Спартанки (Богородск)",sub: "A",fin: ''},
            id5: {id:5,name:"ФШ Импульс",sub: "A",fin: 'C'},
            id6: {id:6,name:"Soccerball",sub: "A",fin: 'C'},
            id7: {id:7,name:"Алмаз",sub: "A",fin: 'D'},
            id8: {id:8,name:"Лилифут Black",sub: "A",fin: 'C'},
            id9: {id:9,name:"Лилифут White", sub: 'B',fin: 'C'},
            id10:{id:10,name:"Академия", sub: 'B',fin: 'C'},
            id11:{id:11,name:"СШОР-8-Чайка",sub: "A",fin: 'D'},
            id12:{id:12,name:"СШОР-8-Юность", sub: 'B',fin: 'D'},
            id13:{id:13,name:"Smile Football-Gray",sub: "A",fin: 'D'},
            id14:{id:14,name:"Smile Football-Blue", sub: 'B',fin: 'D'},
            id15:{id:15,name:"Бриг", sub: 'B',fin: 'C'},
            id16:{id:16,name:'Kids (Дзержинск)', sub: 'B',fin: 'D'},
        }
    }
}

