export const HeaderNav = [
    {
        title: 'Первый шаг (2015)',
        to: '/tournament/1'
    },
    {
        title: 'Первый шаг (2016)',
        to: '/tournament/2'
    },
    {
        title: 'Первый шаг (2017)',
        to: '/tournament/3'
    },
]