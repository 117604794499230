import React, {useRef} from "react";
import {useState} from "react";
import ResultTable from "./ResultTable";
import ChessTable from "./ChessTable";
import TimeTable from "./TimeTable";
import {Link} from "react-router-dom";
// import {exportAsImage} from "../../../Utils";

export default function TournamentContent(props)
{
    const {teamList, games, title, tab, group, showFinal, listStart} = props;
    const [state, updateState] = useState(tab);
    const tabMenu = [
        {value:'result',name:"Результаты",hash:""},
        {value:'chess',name:"Шахматка",hash:"chess"},
        {value:'schedule',name:"Расписание",hash:"schedule"},
    ]
    const exportRef = useRef();
    const setRef = (ref) =>
    {
        if(!ref) return ;
        exportRef.current = ref;
    }

    const JSX = <div>
        <div className="row">
            <div className="col-12 col-md-6 fs-3"><h2 className="comon-heading m-0 mb-3">{title}</h2></div>
            <div className="col-12 col-md-6 text-md-end">
                <div className="tournament-tabs btn-group mb-2 text-center">
                    {tabMenu.map((item,i)=>{
                        const style = (item.value === state)?'active':'';
                        const params = [];
                        if(group&&group!=='A') params.push(['group',group]);
                        if(item.value!=='result') params.push(['tab',item.value]);
                        const url = '?'+params.map(n=>`${n[0]}=${n[1]}`).join('&')
                        return <Link key={i}
                                     to={url}
                                     onClick={e=> updateState(item.value)}
                                     className={`tournament-tabs__item btn btn-dark ${style}`}
                                     >{item.name}</Link>
                    })}
                </div>
            </div>
        </div>
        {state==='result'&&
            <ResultTable
                teamList={teamList}
                final={showFinal}
                getRef={setRef}
                listStart={listStart}/>}
        {state==='chess'&&
            <ChessTable
                teamList={teamList}
                gameList={games}
                getRef={setRef}
                final={showFinal}
                listStart={listStart}/>}
        {state==='schedule'&&<TimeTable gameList={games} teamList={teamList} final={showFinal}/>}
        {/*<button onClick={() => {
            console.log('exportRef',exportRef)
            exportAsImage(exportRef.current, "test")
        }} >DL</button>*/}
    </div>
    return JSX;
}