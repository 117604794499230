export default class Team
{
    constructor(id, name, group, finalGroup) {
        if(group) group = group.toUpperCase();
        if(finalGroup) finalGroup = finalGroup.toUpperCase();
        this.id = Number(id);
        this.name = name;
        this.group = group;
        this.fin = finalGroup;
    }
    gamesCount = 0;
    points = 0;
    goals = 0;
    misses = 0;
    wins=0;
    loses = 0;
    draw = 0;
    gamesCountFinal = 0;
    pointsFinal = 0;
    goalsFinal = 0;
    missesFinal = 0;
    winsFinal=0;
    losesFinal = 0;
    drawFinal = 0;

    increaseGame(final=false)
    {
        final?
            this.gamesCountFinal+=1:
            this.gamesCount+=1;
    }
    updateGameInfo(goals=0, misses=0)
    {
        this.increaseGame();
        this.goals+=goals;
        this.misses+=misses;

        if(goals>misses) {
            this.points+=3;// points = 3;
            this.wins+=1;
        }
        if(goals<misses) {
            this.loses+=1;// points = 0;
        }
        if(goals===misses) {
            this.draw+=1;
            this.points+=1; //points = 1;
        }
        this.ballsDiff = this.goals - this.misses;

    }
    updateFinal(goals=0, misses=0)
    {
        this.increaseGame(true);
        this.goalsFinal+=goals;
        this.missesFinal+=misses;

        if(goals>misses) {
            this.pointsFinal+=3;// points = 3;
            this.winsFinal+=1;
        }
        if(goals<misses) {
            this.losesFinal+=1;// points = 0;
        }
        if(goals===misses) {
            this.drawFinal+=1;
            this.pointsFinal+=1; //points = 1;
        }
        this.ballsDiffFinal = this.goalsFinal - this.missesFinal;

    }
}