import React from "react";
// import shield from "../images/shield-line-icon.svg"
import Utils from "../../../Utils";

export default function TimeTable(props)
{
    const {gameList, teamList} = props;
    const TeamsObject = {};
    teamList.forEach(team=>TeamsObject[team.id]=team.name)

    const groupIDs = teamList.map(item=>Number(item.id))
    let filtered = gameList.filter(item=> {
        return groupIDs.includes(item.t1ID) && groupIDs.includes(item.t2ID)
    })
    filtered = filtered.sort((a,b)=>{
        return new Date(b.date) - new Date(a.date);
    })
    let Arr = {};
    filtered.forEach( (item,i)=>{
        const date = item.date.split(" ")[0];
        if(!Arr[date]) Arr[date] = [];
        Arr[date].push(item);
    })
    const TeamsArray = [];
    teamList.forEach(team=>{
        return teamList.forEach(team2=>{
            let currGame = filtered.map(game=>[game.t1ID, game.t2ID]).find(ids=>team.id!==team2.id&&ids.includes(team.id)&&ids.includes(team2.id));
            if(team!==team2&&!currGame) TeamsArray.push([team.id, team2.id]);
        })

    })

    /*const FutureList = TeamsArray.map((game,i)=>
        <Element key={i}
         game={{date: '0000-00-00 00:00:00', t1ID: game[0],t2ID: game[1],t1Goals: 0, t2Goals: 0, field:0}}
         team1={TeamsObject[game[0]]}
         team2={TeamsObject[game[1]]}
    />)
    const Future = <Block list={FutureList} />*/


    Arr = Object.values(Arr);
    const Result = Arr.map((arr,i)=>{
        const newArr = arr.reverse().map((game,k)=>
            <Element key={k}
                     game={game}
                     //team1={teamList.find(a=>a.id===game.t1ID)}
                     //team2={teamList.find(a=>a.id===game.t2ID)}
                     team1={TeamsObject[game.t1ID]}
                     team2={TeamsObject[game.t2ID]}
            />)
        return <Block key={i} list={newArr} date={arr[0].date}/>
    })

    return <div>{/*Future*/}{Result}</div>
}

function Element(props){
    const
        {game,team1,team2} = props,
        D = new Date(game.date);
        const gameDate = new Date(game.date);

    return(
        <div className="cmimatch">
            <div className="row justify-content-center align-items-center gx-1">
                <div className="col-2 col-md-1">
                    <div className="m-date text-center">{Utils.twoNumbers(D.getHours())}
                        <span>{Utils.twoNumbers(D.getMinutes())}</span>
                    </div>
                </div>
                <div className="col-8 col-md-10">
                    <div className="row fw-bold align-items-center gx-0">
                        <div className="col-10 col-md-5 text-md-end px-1 px-lg-0">{team1}</div>
                        <div className="col-1 col-md-2 d-md-none score-val">{game.t1Goals}</div>
                        <div className="col-2 d-none d-md-block text-center">
                            <span className="score-val">{game.t1Goals}</span>
                            {(!game.t1Goals&&!game.t2Goals&&game.t1Goals!==0&&game.t2Goals!==0)
                            ||gameDate.getTime() > Date.now()?' VS ':' - '}
                            <span className="score-val">{game.t2Goals}</span>
                        </div>
                        <div className="col-10 col-md-5 order-md-1 px-1 px-lg-0">{team2}</div>
                        <div className="col-1 col-md-2 d-md-none score-val">{game.t2Goals}</div>
                    </div>
                    {/*<div className="m-dal d-flex justify-content-between align-items-center">
                        <div className="row text-bold" style={{'flexGrow':1}}>
                            <div className="col-5 text-end">
                                <figure className="d-flex align-items-center justify-content-end m-0">
                                    <img src={shield} alt="ft" style={{'maxHeight':'30px'}} />
                                    <figcaption className="ms-2">
                                        {team1}
                                    </figcaption>
                                </figure>
                            </div>
                            <div className="col-2 text-center">
                                <span> VS </span>
                                {game.t1Goals}
                                {!game.t1Goals&&!game.t2Goals&&game.t1Goals!==0&&game.t2Goals!==0?' VS ':' - '}
                                {game.t2Goals}
                                {`${game.t1Goals} - ${game.t2Goals}`}
                            </div>
                            <div className="col-5">
                                <figure className="d-flex align-items-center m-0">
                                    <img src={shield} alt="ft" style={{'maxHeight':'30px'}} />
                                    <figcaption className="ms-2 b">
                                        {team2}
                                    </figcaption>
                                </figure>

                            </div>
                        </div>


                    </div>*/}
                </div>
                <div className="col-2 col-md-1 cmimatch__field">
                    <p className="loct">Поле <span>#{game.field}</span></p>
                    {/*<a href="#" className="btn details-btn"> View Details <svg xmlns="http://www.w3.org/2000/svg" width="16"
                                                                       height="16" fill="currentColor"
                                                                       className="bi bi-arrow-up-right-square-fill"
                                                                       viewBox="0 0 16 16">
                <path
                    d="M14 0a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12zM5.904 10.803 10 6.707v2.768a.5.5 0 0 0 1 0V5.5a.5.5 0 0 0-.5-.5H6.525a.5.5 0 1 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 .707.707z"></path>
            </svg></a>*/}
                </div>
            </div>
        </div>
    )
}
function Block(props){
    const {date, list} = props,
        D = new Date(date),
        // M2 = ['Янв','Фев','Мар','Апр','Мая','Июн','Июл','Авг','Сен','Окт','Ноя','Дек'],
        //M0 = ['Январь','Февраль','Март','Апрель','Май','Июнь','Июль','Август','Сентябрь','Октябрь','Ноябрь','Декабрь'],
        M1 = ['Января','Февраля','Марта','Апреля','Мая','Июня','Июля','Августа','Сентября','Октября','Ноября','Декабря'];
    const title = D.getDate()?
        `${D.getDate()} ${M1[D.getMonth()]} ${D.getFullYear()}`:
        `Предстоящие игры`
    return (
        <div className="comon-mateches">
            <h3 className="com-in-head"> {title}</h3>
            {list}
        </div>
    )
}