import React from 'react';
import ReactDOM from 'react-dom/client';
// import './index.css';
// import App from './App';
import reportWebVitals from './reportWebVitals';
import {
    // BrowserRouter,
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import RoutesMap from "./RoutesMap";
import Spinner from "./components/Spinner/Spinner";
// import {RoutesMap} from "./RoutesMap";
const router = createBrowserRouter(RoutesMap)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
     {/*<BrowserRouter><App /></BrowserRouter>*/}
    <RouterProvider router={router} fallbackElement={<Spinner />} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
