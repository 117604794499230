import {DB} from "../db/DB";
import Team from "../Class/Team";
// const {teams, year, games} = DB;
export const ProcessedTeams = getProcessTeams();

export default function GetData()
{

}

export function getGroupData()
{}
export function getYearData(year)
{

}

export function getYears()
{
    return DB.year;
}

export function getLastGames()
{
    const Games = [];
    DB.year.forEach(year=>
    {
        DB.games[year].every(game=>game.year = year);
        // console.log('games',DB.games[year])
        Games.push(...DB.games[year]);
    })
    const sortedGames = Games
        .filter(game=>{
            const {date, t1Goals, t2Goals} = game;
            const gameDate = new Date(date);
            return gameDate.getTime() < Date.now() && !(t1Goals===null&t2Goals===null)
        })
        .sort((a,b)=> new Date(b.date) - new Date(a.date))
    const lastDate = sortedGames[0].date.split(" ")[0];

    return Games.filter(game=>game.date.match(lastDate))
}

export function getTeamsShort(year)
{
    const Teams = [];
    Object.values(DB.teams[year]).forEach(team=>Teams[team.id] = team.name);
    return Teams;
}

function getProcessTeams()
{
    const Result = [];
    const Years = DB.year;
    Years.forEach(year=>{
        const Teams = Object.values(DB.teams[year])
            .map(team=>{
                let {id, name, sub} = team;
                if(sub) sub = sub.toUpperCase();
                const T = new Team(id, name, sub);
                T.year = year;
                return T;
            });
        DB.games[year].forEach(game=>{
            const
                team1 = Teams.find(n=>n.id===game.t1ID),
                team2 = Teams.find(n=>n.id===game.t2ID),
                gameDate = new Date(game.date);
            if(gameDate.getTime() > Date.now()) return;
            if(!team1||!team2) return console.error( 'Нет данных для команды #'+(!team1?'1': '2'));
            team1.updateGameInfo(game.t1Goals, game.t2Goals);
            team2.updateGameInfo(game.t2Goals, game.t1Goals);
        })
        Result.push(...Teams);
    })
    return Result;
}