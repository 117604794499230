import React from "react";
// import {ProcessedTeams} from "../../ProcessData";
import LastResults from "./components/LastResults";

export default function (props)
{
    // let PT = ProcessedTeams;

    return <LastResults />

    /*return (<div>
        <LastResults />
        {/!*<div className="latest-news-div">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3">
                        <h2 className="py-3"> Latest News </h2>
                    </div>
                    <div className="col-lg-9">
                        <div className="latest-news owl-carousel owl-theme mt-3 py-3">
                            <a href="#" className="comon-news-links">
                                <i className="far fa-dot-circle"></i> Egals St Club 1 - 0 Defeat to FC Main
                            </a>
                            <a href="#" className="comon-news-links">
                                <i className="far fa-dot-circle"></i> Live Football Scores, Fixtures & Results
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="result-div1 mt-5">
            <div className="container">

                <div className="row gx-lg-5">
                    <div className="col-lg-7 col-xl-8">
                        <div className="d-flex justify-content-between align-items-center">
                            <h2 className="comon-heading m-0"> Fixtures & Results </h2>
                            <a href="#" className="btn all-cm-btn"> All Matches <svg
                                xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                className="bi bi-arrow-right" viewBox="0 0 16 16">
                                <path fillRule="evenodd"
                                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                            </svg></a>
                        </div>
                        <div className="row row-cols-1 row-cols-lg-2 gy-5 g-lg-4 mt-0 mt-lg-0">
                            <div className="col">
                                <a href="#" className="items-matchs">
                                    <figure className="m-0 bg-mc-1">
                                        <img src="images/bg-mc.jpg" alt="bg-ms"/>
                                    </figure>
                                    <div className="matches-div-home">
                                        <h5> Central Olympic Stadium
                                            <span className="d-block"> April 02, 2019 </span>
                                        </h5>
                                        <div className="d-flex align-items-center justify-content-between">

                                            <figure>
                                                <img src="images/1.png" alt="cl2"/>
                                                <figcaption>Istanbul</figcaption>
                                            </figure>
                                            <h4>VS</h4>
                                            <figure>
                                                <img src="images/8.png" alt="cl2"/>
                                                <figcaption>Italy FC.</figcaption>
                                            </figure>
                                        </div>
                                    </div>
                                </a>
                            </div>

                            <div className="col">
                                <a href="#" className="items-matchs">
                                    <figure className="m-0 bg-mc-1">
                                        <img src="images/bg-ms2.jpeg" alt="bg-ms"/>
                                    </figure>
                                    <div className="matches-div-home">
                                        <h5> Central Olympic Stadium
                                            <span className="d-block"> April 02, 2019 </span>
                                        </h5>
                                        <div className="d-flex align-items-center justify-content-between">

                                            <figure>
                                                <img src="images/9.png" alt="cl2"/>
                                                <figcaption>Rayal FC</figcaption>
                                            </figure>
                                            <h4>VS</h4>
                                            <figure>
                                                <img src="images/8.png" alt="cl2"/>
                                                <figcaption>Italy FC.</figcaption>
                                            </figure>
                                        </div>
                                    </div>
                                </a>
                            </div>

                            <div className="col">
                                <a href="#" className="items-matchs">
                                    <figure className="m-0 bg-mc-1">
                                        <img src="images/bg-mc3.jpeg" alt="bg-ms"/>
                                    </figure>
                                    <div className="matches-div-home">
                                        <h5> Central Olympic Stadium
                                            <span className="d-block"> April 02, 2019 </span>
                                        </h5>
                                        <div className="d-flex align-items-center justify-content-between">

                                            <figure>
                                                <img src="images/3.png" alt="cl2"/>
                                                <figcaption>DC Unfo.</figcaption>
                                            </figure>
                                            <h4>VS</h4>
                                            <figure>
                                                <img src="images/9.png" alt="cl2"/>
                                                <figcaption>Italy FC.</figcaption>
                                            </figure>
                                        </div>
                                    </div>
                                </a>
                            </div>


                            <div className="col">
                                <a href="#" className="items-matchs">
                                    <figure className="m-0 bg-mc-1">
                                        <img src="images/bg-mc.jpg" alt="bg-ms"/>
                                    </figure>
                                    <div className="matches-div-home">
                                        <h5> Central Olympic Stadium
                                            <span className="d-block"> April 02, 2019 </span>
                                        </h5>
                                        <div className="d-flex align-items-center justify-content-between">

                                            <figure>
                                                <img src="images/1.png" alt="cl2"/>
                                                <figcaption>Istanbul</figcaption>
                                            </figure>
                                            <h4>VS</h4>
                                            <figure>
                                                <img src="images/8.png" alt="cl2"/>
                                                <figcaption>Italy FC.</figcaption>
                                            </figure>
                                        </div>
                                    </div>
                                </a>
                            </div>

                        </div>

                    </div>
                    <div className="col-lg-5 col-xl-4">
                        <div className="latest-result-div mt-5 mt-lg-0">
                            <div className="d-flex align-items-center justify-content-between">
                                <h2 className="comon-heading m-0"> Latest Results</h2>
                                <a href="#" className="btn viw-btn">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                         fill="currentColor" className="bi bi-arrow-up-right-square-fill"
                                         viewBox="0 0 16 16">
                                        <path
                                            d="M14 0a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12zM5.904 10.803 10 6.707v2.768a.5.5 0 0 0 1 0V5.5a.5.5 0 0 0-.5-.5H6.525a.5.5 0 1 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 .707.707z"/>
                                    </svg>
                                </a>
                            </div>

                            <div className="ltest-divbm mt-4">
                                <a href="#" className="comon-items-div">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="cm-culb">
                                            <figure className="d-flex align-items-center">
                                                <figcaption className="me-2">Italy FC.</figcaption>
                                                <img src="images/fc-1.jpg" alt="cl2"/>

                                            </figure>

                                        </div>
                                        <h5 className="m-0"> 1 - 2 </h5>
                                        <div className="cm-culb">
                                            <figure className="d-flex align-items-center">
                                                <figcaption className="me-2">Italy FC.</figcaption>
                                                <img src="images/fc-2.jpg" alt="cl2"/>

                                            </figure>

                                        </div>
                                    </div>
                                    <p className="text-center"><i className="fas fa-map-marker-alt"></i> Nov 2,
                                        2022/ SGM Stadium</p>
                                </a>

                                <a href="#" className="comon-items-div">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="cm-culb">
                                            <figure className="d-flex align-items-center">
                                                <figcaption className="me-2">Italy FC.</figcaption>
                                                <img src="images/fc-1.jpg" alt="cl2"/>

                                            </figure>

                                        </div>
                                        <h5 className="m-0"> 2 - 0 </h5>
                                        <div className="cm-culb">
                                            <figure className="d-flex align-items-center">
                                                <figcaption className="me-2">Italy FC.</figcaption>
                                                <img src="images/fc-2.jpg" alt="cl2"/>

                                            </figure>

                                        </div>
                                    </div>
                                    <p className="text-center"><i className="fas fa-map-marker-alt"></i> Nov 2,
                                        2022/ SGM Stadium</p>
                                </a>

                                <a href="#" className="comon-items-div">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="cm-culb">
                                            <figure className="d-flex align-items-center">
                                                <figcaption className="me-2">Italy FC.</figcaption>
                                                <img src="images/fc-1.jpg" alt="cl2"/>

                                            </figure>

                                        </div>
                                        <h5 className="m-0"> 1 - 0 </h5>
                                        <div className="cm-culb">
                                            <figure className="d-flex align-items-center">
                                                <figcaption className="me-2">Italy FC.</figcaption>
                                                <img src="images/fc-2.jpg" alt="cl2"/>

                                            </figure>

                                        </div>
                                    </div>
                                    <p className="text-center"><i className="fas fa-map-marker-alt"></i> Nov 2,
                                        2022/ SGM Stadium</p>
                                </a>

                                <a href="#" className="comon-items-div">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="cm-culb">
                                            <figure className="d-flex align-items-center">
                                                <figcaption className="me-2">Italy FC.</figcaption>
                                                <img src="images/fc-1.jpg" alt="cl2"/>

                                            </figure>

                                        </div>
                                        <h5 className="m-0"> 1 - 0 </h5>
                                        <div className="cm-culb">
                                            <figure className="d-flex align-items-center">
                                                <figcaption className="me-2">Italy FC.</figcaption>
                                                <img src="images/fc-2.jpg" alt="cl2"/>

                                            </figure>

                                        </div>
                                    </div>
                                    <p className="text-center"><i className="fas fa-map-marker-alt"></i> Nov 2,
                                        2022/ SGM Stadium</p>
                                </a>

                                <a href="#" className="comon-items-div">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="cm-culb">
                                            <figure className="d-flex align-items-center">
                                                <figcaption className="me-2">Italy FC.</figcaption>
                                                <img src="images/fc-1.jpg" alt="cl2"/>

                                            </figure>

                                        </div>
                                        <h5 className="m-0"> 1 - 0 </h5>
                                        <div className="cm-culb">
                                            <figure className="d-flex align-items-center">
                                                <figcaption className="me-2">Italy FC.</figcaption>
                                                <img src="images/fc-2.jpg" alt="cl2"/>

                                            </figure>

                                        </div>
                                    </div>
                                    <p className="text-center"><i className="fas fa-map-marker-alt"></i> Nov 2,
                                        2022/ SGM Stadium</p>
                                </a>

                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="home-about-part">
            <div className="container">
                <h5> Our History </h5>
                <h2 className="comon-heading m-0"> About the
                    Club SC United</h2>

                <p className="col-lg-7 my-4"> Lorem Ipsum is simply dummy text of the printing and typesetting
                    industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when
                    an unknown printer took a galley of type and scrambled it to make a type specimen book. It
                    has survived not only five centuries, but also the leap into electronic
                    typesetting, remaining essentially unchanged.</p>
                <a href="#" className="btn all-cm-btn"> About More <svg xmlns="http://www.w3.org/2000/svg"
                                                                        width="16" height="16"
                                                                        fill="currentColor"
                                                                        className="bi bi-arrow-right"
                                                                        viewBox="0 0 16 16">
                    <path fillRule="evenodd"
                          d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"></path>
                </svg></a>
            </div>
        </div>

        <div className="table-ag-div py-5">
            <div className="container">
                <h2 className="comon-heading m-0"> League Table & Schedule </h2>

                <div className="row g-lg-5">
                    <div className="col-lg-5">
                        <div className="table-div-left mt-4">
                            <h4> Serie A </h4>


                            <table id="seri1" className="display" style={{"width":"100%"}}>
                                <thead>
                                <tr>
                                    <th>Club</th>
                                    <th>W</th>
                                    <th>D</th>
                                    <th>L</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>

                                        <div className="comon-ft-cl">
                                            <figure className="d-flex align-items-center">
                                                <img src="images/fc-1.jpg" alt="fbn"/>
                                                <figcaption>
                                                    France FC
                                                </figcaption>
                                            </figure>
                                        </div>

                                    </td>
                                    <td>13</td>
                                    <td>01</td>
                                    <td>61</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="comon-ft-cl">
                                            <figure className="d-flex align-items-center">
                                                <img src="images/fc-2.jpg" alt="fbn"/>
                                                <figcaption>
                                                    France FC
                                                </figcaption>
                                            </figure>
                                        </div>
                                    </td>
                                    <td>12</td>
                                    <td>20</td>
                                    <td>61</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="comon-ft-cl">
                                            <figure className="d-flex align-items-center">
                                                <img src="images/fc-1.jpg" alt="fbn"/>
                                                <figcaption>
                                                    France FC
                                                </figcaption>
                                            </figure>
                                        </div>
                                    </td>
                                    <td>25</td>
                                    <td>36</td>
                                    <td>61</td>
                                </tr>

                                <tr>
                                    <td>
                                        <div className="comon-ft-cl">
                                            <figure className="d-flex align-items-center">
                                                <img src="images/fc-1.jpg" alt="fbn"/>
                                                <figcaption>
                                                    France FC
                                                </figcaption>
                                            </figure>
                                        </div>
                                    </td>
                                    <td>25</td>
                                    <td>36</td>
                                    <td>61</td>
                                </tr>

                                <tr>
                                    <td>
                                        <div className="comon-ft-cl">
                                            <figure className="d-flex align-items-center">
                                                <img src="images/fc-1.jpg" alt="fbn"/>
                                                <figcaption>
                                                    France FC
                                                </figcaption>
                                            </figure>
                                        </div>
                                    </td>
                                    <td>12</td>
                                    <td>36</td>
                                    <td>61</td>
                                </tr>


                                </tbody>
                                <tfoot>
                                <tr>
                                    <th>Club</th>
                                    <th>W</th>
                                    <th>D</th>
                                    <th>L</th>
                                </tr>
                                </tfoot>
                            </table>

                        </div>
                    </div>
                    <div className="col-lg-7">
                        <div className="table-div-left mt-4">
                            <h4> Serie A </h4>


                            <table id="seri2" className="display" style={{"width":"100%"}}>
                                <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Event</th>
                                    <th>Time</th>
                                    <th>Venue</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>
                                        Nov 10, 2022
                                    </td>
                                    <td> Royal FC <span>vs</span> GS FC</td>
                                    <td>12:00</td>
                                    <td>GST Stadium</td>
                                </tr>
                                <tr>
                                    <td>
                                        Nov 11, 2022
                                    </td>
                                    <td> Royal FC <span>vs</span> GS FC</td>
                                    <td>14:00</td>
                                    <td>GST Stadium</td>
                                </tr>
                                <tr>
                                    <td>
                                        Nov 12, 2022
                                    </td>
                                    <td> Royal FC <span>vs</span> GS FC</td>
                                    <td>14:30</td>
                                    <td>GST Stadium</td>
                                </tr>

                                <tr>
                                    <td>
                                        Nov 14, 2022
                                    </td>
                                    <td> Royal FC <span>vs</span> GS FC</td>
                                    <td>15:30</td>
                                    <td>GST Stadium</td>
                                </tr>

                                <tr>
                                    <td>
                                        Nov 15, 2022
                                    </td>
                                    <td> Royal FC <span>vs</span> GS FC</td>
                                    <td>19:30</td>
                                    <td>GST Stadium</td>
                                </tr>

                                <tr>
                                    <td>
                                        Nov 15, 2022
                                    </td>
                                    <td> Royal FC <span>vs</span> GS FC</td>
                                    <td>19:30</td>
                                    <td>GST Stadium</td>
                                </tr>


                                </tbody>
                                <tfoot>
                                <tr>
                                    <th>Date</th>
                                    <th>Event</th>
                                    <th>Time</th>
                                    <th>Venue</th>
                                </tr>
                                </tfoot>
                            </table>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="team-div-1">
            <div className="container">
                <div className="d-flex align-items-center justify-content-between">
                    <h2 className="comon-heading m-0"> Players Squad </h2>
                    <a href="#" className="btn all-cm-btn"> Show All <svg xmlns="http://www.w3.org/2000/svg"
                                                                          width="16" height="16"
                                                                          fill="currentColor"
                                                                          className="bi bi-arrow-right"
                                                                          viewBox="0 0 16 16">
                        <path fillRule="evenodd"
                              d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"></path>
                    </svg></a>
                </div>

                <div className="team-slid owl-carousel owl-theme mt-5">
                    <a href="#" className="comon-plyaers">
                        <figure>
                            <img src="images/py1.jpg" alt="team1"/>
                            <figcaption>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                     fill="currentColor" className="bi bi-arrow-up-right-square-fill"
                                     viewBox="0 0 16 16">
                                    <path
                                        d="M14 0a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12zM5.904 10.803 10 6.707v2.768a.5.5 0 0 0 1 0V5.5a.5.5 0 0 0-.5-.5H6.525a.5.5 0 1 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 .707.707z"/>
                                </svg>
                            </figcaption>
                        </figure>
                        <div className="name d-flex align-items-center justify-content-between">
                            <h5> Dominick Dumbleton
                                <span className="d-block"> STRIKER</span>
                            </h5>
                            <span className="num"> 10 </span>
                        </div>
                    </a>

                    <a href="#" className="comon-plyaers">
                        <figure>
                            <img src="images/py2.jpg" alt="team1"/>
                            <figcaption>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                     fill="currentColor" className="bi bi-arrow-up-right-square-fill"
                                     viewBox="0 0 16 16">
                                    <path
                                        d="M14 0a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12zM5.904 10.803 10 6.707v2.768a.5.5 0 0 0 1 0V5.5a.5.5 0 0 0-.5-.5H6.525a.5.5 0 1 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 .707.707z"/>
                                </svg>
                            </figcaption>
                        </figure>
                        <div className="name d-flex align-items-center justify-content-between">
                            <h5> Jores Leperto
                                <span className="d-block"> Defender</span>
                            </h5>
                            <span className="num"> 32 </span>
                        </div>
                    </a>

                    <a href="#" className="comon-plyaers">
                        <figure>
                            <img src="images/py3.jpg" alt="team1"/>
                            <figcaption>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                     fill="currentColor" className="bi bi-arrow-up-right-square-fill"
                                     viewBox="0 0 16 16">
                                    <path
                                        d="M14 0a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12zM5.904 10.803 10 6.707v2.768a.5.5 0 0 0 1 0V5.5a.5.5 0 0 0-.5-.5H6.525a.5.5 0 1 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 .707.707z"/>
                                </svg>
                            </figcaption>
                        </figure>
                        <div className="name d-flex align-items-center justify-content-between">
                            <h5> Sebasti Nikola
                                <span className="d-block"> Forward </span>
                            </h5>
                            <span className="num"> 99 </span>
                        </div>
                    </a>

                    <a href="#" className="comon-plyaers">
                        <figure>
                            <img src="images/py4.jpg" alt="team1"/>
                            <figcaption>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                     fill="currentColor" className="bi bi-arrow-up-right-square-fill"
                                     viewBox="0 0 16 16">
                                    <path
                                        d="M14 0a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12zM5.904 10.803 10 6.707v2.768a.5.5 0 0 0 1 0V5.5a.5.5 0 0 0-.5-.5H6.525a.5.5 0 1 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 .707.707z"/>
                                </svg>
                            </figcaption>
                        </figure>
                        <div className="name d-flex align-items-center justify-content-between">
                            <h5> Ores Luperto
                                <span className="d-block"> STRIKER</span>
                            </h5>
                            <span className="num"> 15 </span>
                        </div>
                    </a>


                </div>
            </div>
        </div>

        <div className="join-us-div">
            <div className="container">
                <div className="d-lg-flex justify-content-between">
                    <h1 className="comon-heading m-0"> Become part of a Great Team </h1>
                    <a href="#" className="btn all-cm-btn mt-4 mt-lg-0"> Join Us <svg
                        xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        className="bi bi-arrow-up-short" viewBox="0 0 16 16">
                        <path fillRule="evenodd"
                              d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z"/>
                    </svg></a>
                </div>
            </div>
        </div>

        <div className="team-products py-5">
            <div className="container">
                <div className="d-flex justify-content-between align-items-center">
                    <h1 className="comon-heading m-0"> Our Team Store </h1>
                    <a href="#" className="btn all-cm-btn"> View All <svg xmlns="http://www.w3.org/2000/svg"
                                                                          width="16" height="16"
                                                                          fill="currentColor"
                                                                          className="bi bi-arrow-right"
                                                                          viewBox="0 0 16 16">
                        <path fillRule="evenodd"
                              d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"></path>
                    </svg></a>
                </div>


                <ul className="nav nav-pills my-4" id="pills-tab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill"
                                data-bs-target="#pills-home"
                                type="button" role="tab" aria-controls="pills-home" aria-selected="true">New
                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill"
                                data-bs-target="#pills-profile"
                                type="button" role="tab" aria-controls="pills-profile"
                                aria-selected="false">Best Selling
                        </button>
                    </li>

                </ul>
                <div className="tab-content " id="pills-tabContent">
                    <div className="tab-pane fade show active" id="pills-home" role="tabpanel"
                         aria-labelledby="pills-home-tab">
                        <div className="news-slid owl-carousel owl-theme">
                            <a href="#" className="items-shop">
                                <div className="img-cm-div">
                                    <figure>
                                        <img src="images/product01.jpg" alt="products"/>
                                        <figcaption>
                                            10% Off
                                        </figcaption>

                                    </figure>
                                    <ul className="list-unstyled hv-hover">
                                        <li>
                                      <span className="btn save">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                           className="bi bi-heart-fill" viewBox="0 0 16 16">
                                          <path fillRule="evenodd"
                                                d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z"/>
                                        </svg>
                                      </span>
                                        </li>
                                        <li>
                                    <span className="btn save">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                           className="bi bi-cart3" viewBox="0 0 16 16">
                                          <path
                                              d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .49.598l-1 5a.5.5 0 0 1-.465.401l-9.397.472L4.415 11H13a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l.84 4.479 9.144-.459L13.89 4H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                                        </svg>
                                    </span>
                                        </li>
                                    </ul>
                                </div>
                                <h6> T-shirt </h6>
                                <h5> soccer jersey </h5>
                                <h4> $45</h4>
                            </a>

                            <a href="#" className="items-shop">
                                <div className="img-cm-div">
                                    <figure>
                                        <img src="images/product02.webp" alt="products"/>
                                    </figure>
                                    <ul className="list-unstyled hv-hover">
                                        <li>
                                  <span className="btn save">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                         className="bi bi-heart-fill" viewBox="0 0 16 16">
                                      <path fillRule="evenodd"
                                            d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z"/>
                                    </svg>
                                  </span>
                                        </li>
                                        <li>
                                <span className="btn save">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                         className="bi bi-cart3" viewBox="0 0 16 16">
                                      <path
                                          d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .49.598l-1 5a.5.5 0 0 1-.465.401l-9.397.472L4.415 11H13a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l.84 4.479 9.144-.459L13.89 4H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                                    </svg>
                                </span>
                                        </li>
                                    </ul>
                                </div>

                                <h6> Shoes </h6>
                                <h5> soccer jersey </h5>
                                <h4> $15</h4>
                            </a>

                            <a href="#" className="items-shop">
                                <div className="img-cm-div">
                                    <figure>
                                        <img src="images/product-13-copyright-393x426.webp" alt="products"/>
                                        <figcaption>
                                            5% Off
                                        </figcaption>

                                    </figure>
                                    <ul className="list-unstyled hv-hover">
                                        <li>
                                  <span className="btn save">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                         className="bi bi-heart-fill" viewBox="0 0 16 16">
                                      <path fillRule="evenodd"
                                            d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z"/>
                                    </svg>
                                  </span>
                                        </li>
                                        <li>
                                <span className="btn save">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                         className="bi bi-cart3" viewBox="0 0 16 16">
                                      <path
                                          d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .49.598l-1 5a.5.5 0 0 1-.465.401l-9.397.472L4.415 11H13a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l.84 4.479 9.144-.459L13.89 4H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                                    </svg>
                                </span>
                                        </li>
                                    </ul>
                                </div>

                                <h6> Gloves </h6>
                                <h5> soccer jersey </h5>
                                <h4> $45</h4>
                            </a>


                            <a href="#" className="items-shop">
                                <div className="img-cm-div">
                                    <figure>
                                        <img src="images/4.jpg" alt="products"/>

                                    </figure>
                                    <ul className="list-unstyled hv-hover">
                                        <li>
                                  <span className="btn save">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                         className="bi bi-heart-fill" viewBox="0 0 16 16">
                                      <path fillRule="evenodd"
                                            d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z"/>
                                    </svg>
                                  </span>
                                        </li>
                                        <li>
                                <span className="btn save">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                         className="bi bi-cart3" viewBox="0 0 16 16">
                                      <path
                                          d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .49.598l-1 5a.5.5 0 0 1-.465.401l-9.397.472L4.415 11H13a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l.84 4.479 9.144-.459L13.89 4H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                                    </svg>
                                </span>
                                        </li>
                                    </ul>
                                </div>

                                <h6> t-shirt </h6>
                                <h5> soccer jersey </h5>
                                <h4> $25</h4>
                            </a>


                        </div>
                    </div>
                    <div className="tab-pane fade" id="pills-profile" role="tabpanel"
                         aria-labelledby="pills-profile-tab">
                        <div className="news-slid owl-carousel owl-theme">
                            <a href="#" className="items-shop">
                                <div className="img-cm-div">
                                    <figure>
                                        <img src="images/product01.jpg" alt="products"/>
                                        <figcaption>
                                            10% Off
                                        </figcaption>

                                    </figure>
                                    <ul className="list-unstyled hv-hover">
                                        <li>
                                  <span className="btn save">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                         className="bi bi-heart-fill" viewBox="0 0 16 16">
                                      <path fillRule="evenodd"
                                            d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z"/>
                                    </svg>
                                  </span>
                                        </li>
                                        <li>
                                <span className="btn save">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                         className="bi bi-cart3" viewBox="0 0 16 16">
                                      <path
                                          d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .49.598l-1 5a.5.5 0 0 1-.465.401l-9.397.472L4.415 11H13a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l.84 4.479 9.144-.459L13.89 4H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                                    </svg>
                                </span>
                                        </li>
                                    </ul>
                                </div>

                                <h6> T-shirt </h6>
                                <h5> soccer jersey </h5>
                                <h4> $45</h4>
                            </a>

                            <a href="#" className="items-shop">
                                <div className="img-cm-div">
                                    <figure>
                                        <img src="images/product02.webp" alt="products"/>


                                    </figure>
                                    <ul className="list-unstyled hv-hover">
                                        <li>
                                  <span className="btn save">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                         className="bi bi-heart-fill" viewBox="0 0 16 16">
                                      <path fillRule="evenodd"
                                            d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z"/>
                                    </svg>
                                  </span>
                                        </li>
                                        <li>
                                <span className="btn save">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                         className="bi bi-cart3" viewBox="0 0 16 16">
                                      <path
                                          d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .49.598l-1 5a.5.5 0 0 1-.465.401l-9.397.472L4.415 11H13a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l.84 4.479 9.144-.459L13.89 4H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                                    </svg>
                                </span>
                                        </li>
                                    </ul>
                                </div>

                                <h6> Shoes </h6>
                                <h5> soccer jersey </h5>
                                <h4> $15</h4>
                            </a>

                            <a href="#" className="items-shop">
                                <div className="img-cm-div">
                                    <figure>
                                        <img src="images/product-13-copyright-393x426.webp" alt="products"/>
                                        <figcaption>
                                            5% Off
                                        </figcaption>

                                    </figure>
                                    <ul className="list-unstyled hv-hover">
                                        <li>
                                <span className="btn save">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                       className="bi bi-heart-fill" viewBox="0 0 16 16">
                                    <path fillRule="evenodd"
                                          d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z"/>
                                  </svg>
                                </span>
                                        </li>
                                        <li>
                              <span className="btn save">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                       className="bi bi-cart3" viewBox="0 0 16 16">
                                    <path
                                        d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .49.598l-1 5a.5.5 0 0 1-.465.401l-9.397.472L4.415 11H13a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l.84 4.479 9.144-.459L13.89 4H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                                  </svg>
                              </span>
                                        </li>
                                    </ul>
                                </div>

                                <h6> Gloves </h6>
                                <h5> soccer jersey </h5>
                                <h4> $45</h4>
                            </a>


                            <a href="#" className="items-shop">
                                <div className="img-cm-div">
                                    <figure>
                                        <img src="images/4.jpg" alt="products"/>

                                    </figure>
                                    <ul className="list-unstyled hv-hover">
                                        <li>
                                <span className="btn save">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                       className="bi bi-heart-fill" viewBox="0 0 16 16">
                                    <path fillRule="evenodd"
                                          d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z"/>
                                  </svg>
                                </span>
                                        </li>
                                        <li>
                              <span className="btn save">

                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                       className="bi bi-cart3" viewBox="0 0 16 16">
                                    <path
                                        d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .49.598l-1 5a.5.5 0 0 1-.465.401l-9.397.472L4.415 11H13a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l.84 4.479 9.144-.459L13.89 4H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                                  </svg>

                              </span>
                                        </li>
                                    </ul>
                                </div>

                                <h6> t-shirt </h6>
                                <h5> soccer jersey </h5>
                                <h4> $25</h4>
                            </a>


                        </div>
                    </div>
                </div>


            </div>
        </div>

        <div className="newd-blogs-div py-5">
            <div className="container">
                <div className="d-flex align-items-center justify-content-between">

                    <h2 className="comon-heading m-0"> News & Media Gallery </h2>

                    <a href="#" className="btn all-cm-btn"> View All <svg xmlns="http://www.w3.org/2000/svg"
                                                                          width="16" height="16"
                                                                          fill="currentColor"
                                                                          className="bi bi-arrow-right"
                                                                          viewBox="0 0 16 16">
                        <path fillRule="evenodd"
                              d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"></path>
                    </svg></a>

                </div>


                <div className="row g-lg-5 mt-5 mt-lg-0">
                    <div className="col-lg-8">
                        <a href="#" className="left-cm-blogs">
                            <figure className="m-0">
                                <img src="images/blogs-tg.webp" alt="blogs"/>
                            </figure>
                            <div className="blogs-ps-right ps-lg-4 pt-lg-4">
                                <h5> Draymond Green had bizarre reason </h5>
                                <ul className="list-unstyled d-flex align-items-center mt-2">
                                    <li>
                                        <i className="fas fa-calendar-alt"></i> 27 June, 2020
                                    </li>
                                    <li>
                                        <i className="far fa-comment-dots"></i> 89 Comments
                                    </li>
                                </ul>
                                <p className="mt-2"> Lorem Ipsum is simply dummy text of the printing and
                                    typesetting industry.
                                    the printing and typesetting industry.
                                </p>
                                <h4 className="btn mt-4">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                         fill="currentColor" className="bi bi-arrow-up-right-square-fill"
                                         viewBox="0 0 16 16">
                                        <path
                                            d="M14 0a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12zM5.904 10.803 10 6.707v2.768a.5.5 0 0 0 1 0V5.5a.5.5 0 0 0-.5-.5H6.525a.5.5 0 1 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 .707.707z"/>
                                    </svg>
                                </h4>
                            </div>
                        </a>

                        <a href="#" className="left-cm-blogs">
                            <figure className="m-0">
                                <img src="images/pso.webp" alt="blogs"/>
                            </figure>
                            <div className="blogs-ps-right ps-lg-4 pt-lg-4">
                                <h5> Draymond Green had bizarre reason </h5>
                                <ul className="list-unstyled d-flex align-items-center mt-2">
                                    <li>
                                        <i className="fas fa-calendar-alt"></i> 27 June, 2020
                                    </li>
                                    <li>
                                        <i className="far fa-comment-dots"></i> 89 Comments
                                    </li>
                                </ul>
                                <p className="mt-2"> Lorem Ipsum is simply dummy text of the printing and
                                    typesetting industry. the printing and typesetting industry.
                                </p>
                                <h4 className="btn mt-4">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                         fill="currentColor" className="bi bi-arrow-up-right-square-fill"
                                         viewBox="0 0 16 16">
                                        <path
                                            d="M14 0a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12zM5.904 10.803 10 6.707v2.768a.5.5 0 0 0 1 0V5.5a.5.5 0 0 0-.5-.5H6.525a.5.5 0 1 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 .707.707z"/>
                                    </svg>
                                </h4>
                            </div>
                        </a>


                    </div>

                    <div className="col-lg-4 right-home-blogs">
                        <a href="#" className="left-cm-blogs">
                            <figure className="m-0">
                                <img src="images/gfs.jpg" alt="blogs"/>
                            </figure>
                            <div className="blogs-ps-right ps-lg-4 pt-lg-1">
                                <h5 className="mt-0"> Draymond Green had bizarre reason </h5>
                                <ul className="list-unstyled d-flex align-items-center mt-2">
                                    <li>
                                        <i className="fas fa-calendar-alt"></i> 27 June, 2020
                                    </li>
                                    <li>
                                        <i className="far fa-comment-dots"></i> 89 Comments
                                    </li>
                                </ul>

                            </div>
                        </a>

                        <a href="#" className="left-cm-blogs">
                            <figure className="m-0">
                                <img src="images/fgh.jpg" alt="blogs"/>
                            </figure>
                            <div className="blogs-ps-right ps-lg-4 pt-lg-1">
                                <h5 className="mt-0"> Draymond Green had bizarre reason </h5>
                                <ul className="list-unstyled d-flex align-items-center mt-2">
                                    <li>
                                        <i className="fas fa-calendar-alt"></i> 27 June, 2020
                                    </li>
                                    <li>
                                        <i className="far fa-comment-dots"></i> 89 Comments
                                    </li>
                                </ul>

                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>*!/}
    </div>)*/
}