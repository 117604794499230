import React from "react";
// import App from "./App";
import ResultTable from "./components/ResultTable";
import ErrorPage from "./components/ErrorPage";
// import HomePage from "./components/HomePage";
import {tournamentLoader} from "./components/Tournament";
// import Layout from "./components/Layout";
// import BraveTheme from "./components/themes/brave/BraveTheme";
import Soccer from "./components/themes/soccer/Soccer";
import Shedule from "./components/themes/soccer/Shedule";
import HomePage from "./components/themes/soccer/HomePage";

const RoutesMap = [
    {
        path: "/",
        element: <Soccer />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: 'tournament/:id',
                // element: <Tournament />,
                element: <Shedule />,
                loader: tournamentLoader
            },
            {
                path: '/',
                element: <HomePage />
            },
            {
                path: '/2015',
                element: <ResultTable selectedGroup="2015" />
            },

            {
                path: '/2016',
                element: ['a2016','b2016'].map(group=><ResultTable key={group} selectedGroup={group} />)
            },
            {
                path: '/2017',
                element: <ResultTable selectedGroup="2017" />
            }
        ],
    },
    /*{
        path: 'tournament/:id',
        element: <Layout><Tournament /></Layout>
    },*/
    /*{
        path: 'result-table/:id',
        element: <Layout><ResultTable/></Layout>
    },*/
];
export default RoutesMap;