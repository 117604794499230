import React, {useEffect, useRef} from "react";
// import {exportAsImage}  from "../../../Utils";
export default function ResultTable (props)
{
    const {teamList, final, getRef, listStart} = props;
    const exportRef = useRef();
    useEffect(()=>{
        getRef(exportRef.current);
   })
    let num = listStart;

    const sfx = final?'Final':'';
    return (
    <div className="result-table__wrap" ref={exportRef}>
        <table className="table table-light table-striped table-hover table-bordered text-center">
            <thead className="table-dark">
                <tr>
                    <th scope="col" className="col-num">#</th>
                    <th scope="col" className="text-start">Команда</th>
                    <th scope="col" className="" title="Игр">И</th>
                    <th scope="col" className="" title="Выигрышей">В</th>
                    <th scope="col" className="" title="Поражений">П</th>
                    <th scope="col" className="" title="Ничья">Н</th>
                    <th scope="col" className="col-balls" title="Мячей">М</th>
                    <th scope="col" className="" title="Очков">О</th>
                </tr>
            </thead>
            <tbody>{
            teamList.map((item,i)=> {
                // let num = number?number++:++i;
                return <tr key={item.id} className="">
                    <th className="col-num" scope="row">{num++}</th>
                    <td className="text-start">{item.name}</td>
                    <td className="">{item['gamesCount'+sfx]}</td>
                    <td className="">{item['wins'+sfx]}</td>
                    <td className="">{item['loses'+sfx]}</td>
                    <td className="">{item['draw'+sfx]}</td>
                    <td className="text-nowrap col-balls">{item['goals'+sfx]} - {item['misses'+sfx]}</td>
                    <td className="">{item['points'+sfx]}</td>
                </tr>
            })
            }</tbody>
        </table>
        {/*<button onClick={() => exportAsImage(exportRef.current, "test")} >DL</button>*/}
    </div>
    )
}