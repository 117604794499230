import React from "react";

export default function Footer(props)
{
    return (
        <footer className="footer py-5 float-start w-100">

            <div className="container">
                {/*<div className="row row-cols-1 row-cols-lg-3">
                    <div className="col">
                        <div className="comon-footer">
                            <h5> Contact Info </h5>
                            <p className="col-lg-10">We're a professional football club in Kolkata, India, founded
                                in 1990.
                                It is a long established fact.
                            </p>
                            <ul className="list-unstyled d-flex align-items-center mt-2">
                                <li>
                                    <a href="#"> <i className="fab fa-facebook"></i> </a>
                                </li>
                                <li className="mx-2">
                                    <a href="#"> <i className="fab fa-twitter"></i> </a>
                                </li>
                                <li>
                                    <a href="#"><i className="fab fa-instagram"></i> </a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="col">
                        <div className="comon-footer">
                            <h5> Resources </h5>
                            <ul className="list-unstyled">
                                <li>
                                    <a href="#"> Matches </a>
                                </li>
                                <li>
                                    <a href="#"> The Club </a>
                                </li>
                                <li>
                                    <a href="#"> Member </a>
                                </li>
                                <li>
                                    <a href="#"> News </a>
                                </li>
                                <li>
                                    <a href="#"> Players </a>
                                </li>

                                <li>
                                    <a href="#"> Media </a>
                                </li>

                                <li>
                                    <a href="#"> Shop </a>
                                </li>

                                <li>
                                    <a href="#"> Contact </a>
                                </li>
                            </ul>

                        </div>
                    </div>

                    <div className="col">
                        <div className="comon-footer">
                            <h5>Newsletter</h5>
                            <p> We'll send updates straight to your Mail. Let's Do stay connected!</p>
                            <div className="d-flex mt-3 align-items-center">
                                <input type="text" className="form-control" placeholder="Enter Email"/>
                                <button type="submit" className="btn scub-btn">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                         fill="currentColor" className="bi bi-arrow-up-right-square-fill"
                                         viewBox="0 0 16 16">
                                        <path
                                            d="M14 0a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12zM5.904 10.803 10 6.707v2.768a.5.5 0 0 0 1 0V5.5a.5.5 0 0 0-.5-.5H6.525a.5.5 0 1 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 .707.707z"/>
                                    </svg>
                                </button>
                            </div>

                        </div>
                    </div>

                </div>
                <hr/>*/}
                <p className="text-center copy-t"> &copy; 2023 Football Score, All Right Reserved</p>
                <p className="text-center copy-t">Разработано в <a title="Создание сайтов и web-приложений" href="https://sinaps-pro.ru">Sinaps-Pro</a></p>
            </div>
        </footer>
    )
}