import React, {Fragment, useState} from "react";
//import "https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@300;400;500;600;700;800&family=Cormorant:wght@500&family=Josefin+Sans:wght@400;500;600&family=Merriweather:wght@300;400;700;900&family=Nunito:wght@300;400;500;600&family=Open+Sans:wght@300;400;500;600;700;800&family=Playfair+Display:wght@400;500;600;700;800;900&family=Quicksand:wght@300;400;500;600;700&family=Roboto:wght@300;400;500;700&family=Saira+Condensed:wght@400;500;600;700&display=swap"
import "./css/bootstrap.min.css"
import "./css/all.min.css"
import "./css/style.css"
import Header from "./Header";
// import SubBanner from "./SubBanner";
import logo from "./images/logo3.svg"
import {HeaderNav} from "../../Routing";
import {Outlet} from "react-router-dom";
import Footer from "./Footer";
import favicon from "./favicon.ico"
import MobileMenu from "./components/MobileMenu";

export default function Soccer(props)
{
    let favLnk = document.querySelector('link[rel="icon"]');
    const [mobileMenuVisibility,updateMobileMenuVisibility] = useState(false);
    favLnk.href = favicon;
    return (
        <Fragment>
            <Header logo={logo} nav={HeaderNav} mobileMenuVisibility={mobileMenuVisibility} showMobileMenu={updateMobileMenuVisibility}  />
            <Outlet />
            <Footer />
            <MobileMenu nav={HeaderNav} visibility={mobileMenuVisibility} showMobileMenu={updateMobileMenuVisibility} />
        </Fragment>
    )
}
