import React, {useState} from "react";
import {DB} from "../../../../db/DB";
import Team from  "../../../../Class/Team"
import TournamentContent from "./TournamentContent";
import "./../css/Tournament.css"
import {Link, useLocation, useSearchParams} from "react-router-dom";

export function tournamentLoader({params})
{
    return params;
}
export default function Tournament(props)
{
    /** Get Team Year **/
    const {year} = props;

    const [searchParams] = useSearchParams();
    let group, tab

    switch (searchParams.get('tab'))
    {
        case 'chess': tab = 'chess'; break;
        case 'schedule': tab = 'schedule'; break;
        default: tab = 'result';
    }
    group = searchParams.get('group');


    /** Get Teams List with Data  **/
    const {groupTeams, groups, games} = GetResult(year);

    let JSX = [];
    const Teams = {};
    groups.push("C","D");
    /** Separate by groups if needed **/
    if(groups.length){
        const [currentGroup, changeGroup] = useState(group||groups[0]);
        function getParams()
        {
            const search = new URLSearchParams(useLocation().search);
            return  {tab:search.get('tab'),group: search.get('group')};
        }
        for( let key in groupTeams){
            const g = groupTeams[key].group;
            if(!Teams[g]) Teams[g] = {};
            Teams[g][key] = groupTeams[key];
            const f = groupTeams[key].fin;
            if(!f) continue;
            if(!Teams[f]) Teams[f] = {};
            Teams[f][key] = groupTeams[key];
        }

        const title = getTitle(year+currentGroup, `группа ${currentGroup}`);

        const groupsMenu =
            <ul className="nav nav-pills mb-3 mt-3" id="pills-tab" role="tablist">
                {groups.map((group,i)=>{
                    const btnClassName = group===currentGroup?'nav-link active':'nav-link';
                    const params = [];

                    const {tab} = getParams();
                    if(group&&group!=='A') params.push(['group',group]);
                    if(tab&&tab!=='result') params.push(['tab',tab]);
                    const url = '?'+params.map(n=>`${n[0]}=${n[1]}`).join('&')

                    return (
                        <li key={i} className="nav-item" role="presentation">
                            <Link
                                to={url}
                                className={btnClassName}
                                onClick={e=> changeGroup(group)}
                                type="button">{getTitle(year+group,`группа ${group}`)}
                            </Link>
                        </li>)
                })}
            </ul>
        // let TeamList = SortTeamList(Teams[currentGroup]);
        const TeamList = ['A','B'].includes(currentGroup)?
            SortTeamList(Teams[currentGroup]):
            SortTeamList(Teams[currentGroup], true);

        /*if(year===2016&&currentGroup==='B'){
            const gs = games.filter(game=>{
                const {t1ID,t2ID} = game;
                return [t1ID, t2ID].includes(5);
            }).sort((a,b)=>{
                const
                    a1 = a.t1ID, a2 = a.t2ID,
                    b1 = b.t1ID, b2 = b.t2ID;
                const c1 = a1===5?a2:a1;
                const c2 = b1===5?b2:b1;
                if(c1>c2) return -1
                else if(c1<c2) return 1;
                else return 0;
            })

           /!* const gs = games.filter(game=> {
                const {t1ID, t2ID} = game;
                return [2,5].includes(t1ID)&&[2,5].includes(t2ID)
                // return [t1ID, t2ID].includes(5, 2);
            })*!/
            console.log(gs)
            /!*gs.forEach(game=>{
                const {t1ID,t2ID,t1Goals,t2Goals} = game;
                const data = t1ID ===5?
                    `${t1ID}: ${t1Goals}-${t2Goals} :${t2ID}`:
                    `${t2ID}: ${t2Goals}-${t1Goals} :${t1ID}`;
                console.log(data)
            })*!/
        }*/
        /*if ( ['C','D'].includes(currentGroup))
        {
            let minLength;
            switch (year+currentGroup){
                case '2016C':minLength = 10;break;
                case '2016D':minLength = 12;break;
                case '2015C':case '2015D':
                case '2017C':case '2017D':
                default: minLength = 8;
            }
            console.log(TeamList.length<minLength,TeamList.length,minLength);
            if(TeamList.length<minLength)
            {
                let A = new Array(15);
                console.log('AD', A)
                for (let i=15;i--;)
                A = A.map((n,i) => new Team())
                console.log('DA', A)
                A.forEach((n,i)=>console.log('item '+i++))
            }

        }*/
        JSX = <div className="our-history-div">{groupsMenu}<TournamentContent
            teamList={TeamList}
            games={games}
            title={title}
            tab={tab}
            group={currentGroup}
            showFinal={['C','D'].includes(currentGroup)}
            listStart={getListStart(year+currentGroup)}
            // groups={groups}
            // changeGroup={changeGroup}
        /></div>
    }else{
        const Teams = SortTeamList(groupTeams);
        JSX = <TournamentContent teamList={Teams} games={games} />;
    }
    return JSX;
}

function SortTeamList(TeamList, final)
{
    const sfx = final?'Final':'';
    if(!TeamList||!Object.values(TeamList).length) return [];
    return Object.values(TeamList).sort((a,b)=>{
        if(a['points'+sfx]>b['points'+sfx]) return -1
        else if(a['points'+sfx]<b['points'+sfx]) return 1;
        else if(a['ballsDiff'+sfx]>b['ballsDiff'+sfx]) return -1;
        else if(a['ballsDiff'+sfx]<b['ballsDiff'+sfx]) return 1;
        else return 0;
    })
}

function GetResult(Year)
{
    const {teams, groups, games} = DB;
    const currentGroup = Year || groups[0];
    const groupTeams = {};
    const subGroups = [];
    for (let key in teams[currentGroup])
    {
        let {id, name, sub, fin} = teams[currentGroup][key];
        if(sub) sub = sub.toUpperCase();
        if(sub&&!subGroups.includes(sub)) subGroups.push(sub);
        groupTeams[key] = new Team(id, name, sub, fin);
    }
    const groupGames = games[currentGroup];
    groupGames.forEach(game=>{
        const {t1Goals, t2Goals} = game;
        const team1 = 'id'+game.t1ID;
        const team2 = 'id'+game.t2ID;
        const gameDate = new Date(game.date),
            T1 = groupTeams[team1],
            T2 = groupTeams[team2];
        if(!(groupTeams[team1]&&groupTeams[team2])) return;
        if(gameDate.getTime() > Date.now()) return;



        if(game.t1Goals===null||game.t2Goals===null) return;
       if(T1.fin&&T2.fin&&T1.fin===T2.fin){
           T1.updateFinal(t1Goals, t2Goals);
           T2.updateFinal(t2Goals, t1Goals);
       }
        if(T1.group!==T2.group)
        {
           return;
        }
        // if(groupTeams[team1].group!==groupTeams[team2].group) return;
        groupTeams[team1].updateGameInfo(t1Goals, t2Goals);
        groupTeams[team2].updateGameInfo(t2Goals, t1Goals);
    })

    return {groupTeams, groups: subGroups,games: games[currentGroup]};
}

function getTitle(value, defaultValue)
{
    defaultValue = defaultValue||"";
    switch (value)
    {
        case '2015C':
        case '2017C': return 'Места 1-8';
        case '2015D':
        case '2017D': return 'Места 9-15';
        case '2016C': return 'Места 1-10';
        case '2016D': return 'Места 11-21';
        default: return  defaultValue;
    }
}

function getListStart(value){
    switch (value)
    {
        case '2016D': return 11;
        case '2015D':
        case '2017D': return 9;
        case '2015C':
        case '2017C':
        case '2016C':
        default: return  1;
    }
}