import React from "react";
import {getLastGames, getTeamsShort, getYears} from "../../../ProcessData";
import {NavLink} from "react-router-dom";

export default function LastResults(props)
{
    const
        games = getLastGames(),
        Years = getYears();
    let JSX = Years.map((year, i)=>{
        const gamesList = games.filter(game=>game.year===year).reverse()
        let tID;
        switch (year)
        {
            case 2017: tID = 3;break;
            case 2016: tID = 2;break;
            case 2015:
            default:
                tID = 1;
        }
        return(
            <div key={Rand()} className="col-12 col-lg-4">
                <h3 key={`title-${i}`} className="h6 my-2 "><NavLink to={`/tournament/${tID}`}>Первый шаг {year}</NavLink></h3>
                <div key={i} className="ltest-divbm">
                    <Elements key={year+i}  list={gamesList} year={year} />
                    <div className="text-center pt-2"><NavLink className="btn btn-link" to={`/tournament/${tID}?tab=schedule`}>Все игры</NavLink></div>
                </div>
            </div>);
    })
    return (
        <div className="container mb-4">
            <div className="latest-result-div mt-3">
                <div className="d-flex align-items-center justify-content-between">
                    <h2 className="comon-heading m-0 h3">Недавние игры</h2>
                    {/*<a href="#" className="btn viw-btn">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                             fill="currentColor" className="bi bi-arrow-up-right-square-fill"
                             viewBox="0 0 16 16">
                            <path
                                d="M14 0a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12zM5.904 10.803 10 6.707v2.768a.5.5 0 0 0 1 0V5.5a.5.5 0 0 0-.5-.5H6.525a.5.5 0 1 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 .707.707z"/>
                        </svg>
                    </a>*/}
                </div>
                <div className="row">{JSX}</div>
            </div>
        </div>
    )
}

function Elements(props)
{
    const
        Teams = getTeamsShort(props.year),
        M = ['Янв','Фев','Мар','Апр','Мая','Июн','Июл','Авг','Сен','Окт','Ноя','Дек'],
        maxLength = 15;

    return(
        props.list.map((game,i)=>{
            const
                D = new Date(game.date),
                t1ClsName = Teams[game.t1ID].length>maxLength?'text-small':"",
                t2ClsName = Teams[game.t2ID].length>maxLength?'text-small':"";
            if(game.t1Goals===null) return false;
            return(
                <div key={i} className="comon-items-div">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="cm-culb">
                            <figure className="d-flex align-items-center justify-content-center">
                                <figcaption className={`text-center ${t1ClsName}`}>{Teams[game.t1ID]}</figcaption>
                                {/*<img src="images/fc-1.jpg" alt="cl2"/>*/}
                            </figure>
                        </div>
                        <h5 className="m-0 text-nowrap"> {game.t1Goals} - {game.t2Goals} </h5>
                        <div className="cm-culb">
                            <figure className="d-flex align-items-center justify-content-center">
                                <figcaption className={`text-center ${t2ClsName}`}>{Teams[game.t2ID]}</figcaption>
                                {/*<img src="images/fc-2.jpg" alt="cl2"/>*/}
                            </figure>
                        </div>
                    </div>
                    <p className="text-center">
                        {/*<i className="fas fa-map-marker-alt"></i> */}
                        {D.getDate()} {M[D.getMonth()]} {D.getFullYear()} {/*{D.getHours()}:{D.getMinutes()}*/} / Поле #{game.field}</p>
                </div>
            )
        })

    )
}

function randomInteger(min, max) {
    // случайное число от min до (max+1)
    let rand = min + Math.random() * (max + 1 - min);
    return Math.floor(rand);
}
function Rand() {
    return randomInteger(100, 999);
}