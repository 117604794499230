import React, {Fragment, useState} from "react";
import {DB} from "../db/DB";
import Team from "../Class/Team";
export default function ResultTable (props)
{
    const [resultData, updateResultData] = useState([]);
    const [currentGroup, updateCurrentGroup] = useState();
    // console.log('ResultTable props', props)
    if(props.selectedGroup !== currentGroup) {
        updateCurrentGroup(props.selectedGroup);
        // updateH1(`Команды ${props.selectedGroup}`)
        const groupTeams = GetResult(props.selectedGroup)
        updateResultData(Object.values(groupTeams).sort((a,b)=>{
            if(a.points>b.points) return -1
            else if(a.points<b.points) return 1;
            else if(a.ballsDiff>b.ballsDiff) return -1;
            else if(a.ballsDiff<b.ballsDiff) return 1;
            else return 0;
        }));
    }

    return <Fragment><h3>Первый шаг - {props.selectedGroup}</h3><div className="result-table-wrap">
        <div className="result-table">
            <div className="result-table-row result-table-head">
                <div className="result-table-col">#</div>
                <div className="result-table-col result-table-col__name">Команда</div>
                <div className="result-table-col" title="Игр">И</div>
                <div className="result-table-col" title="Выигрышей">В</div>
                <div className="result-table-col" title="Поражений">П</div>
                <div className="result-table-col" title="Ничья">Н</div>
                <div className="result-table-col" title="Мячей">М</div>
                <div className="result-table-col" title="Очков">О</div>
            </div>
        {
            resultData.map((item,i)=>
            <div key={item.id} className="result-table-row">
                <div className="result-table-col">{i+1}</div>
                <div className="result-table-col result-table-col__name">{item.name}</div>
                <div className="result-table-col">{item.gamesCount}</div>
                <div className="result-table-col">{item.wins}</div>
                <div className="result-table-col">{item.loses}</div>
                <div className="result-table-col">{item.draw}</div>
                <div className="result-table-col">{item.goals} - {item.misses}</div>
                <div className="result-table-col">{item.points}</div>
            </div>)
        }

    </div></div></Fragment>
}

export function GetResult(selectedGroup)
{
    const {teams, groups, games} = DB;
    const currentGroup = selectedGroup || groups[0];
    const groupTeams = teams[currentGroup];
    for (let key in groupTeams)
    {
        groupTeams[key] = new Team(groupTeams[key].id, groupTeams[key].name);
    }
    const groupGames = games[currentGroup];
    groupGames.forEach(game=>{
        const {t1Goals, t2Goals} = game;
        const team1 = 'id'+game.t1ID;
        const team2 = 'id'+game.t2ID;
        const gameDate = new Date(game.date);
        if(gameDate.getTime() > Date.now()) return;
        groupTeams[team1].updateGameInfo(t1Goals, t2Goals);
        groupTeams[team2].updateGameInfo(t2Goals, t1Goals);
    })

    return groupTeams;
}