import React, {Fragment} from "react";
import SubBanner from "./SubBanner";
import {useLoaderData} from "react-router-dom";
import Tournament from "./components/Tournament";

export function tournamentLoader({params})
{
    return params;
}

export default function Shedule()
{
    const {id} = useLoaderData();
    const year = (id=> {
        switch (id) {
            case "1": return 2015;
            case "2": return 2016;
            case "3": return 2017;
            default: return "";
        }
    })(id);
    return (
        <Fragment>
            <SubBanner title={`Первый шаг ${year}`} />
            <section className="body-part-total float-start w-100">
                <div className="container">
                    <Tournament year={year} />
                </div>
            </section>
        </Fragment>
    )
}