import React, {Fragment} from "react";
import {useLoaderData} from "react-router-dom";
import ResultTable from "./ResultTable2";
import {DB} from "../db/DB";
import Team from "../Class/Team";
import ChessTable from "./ChessTable";

export function tournamentLoader({params})
{
    return params;
}
export default function Tournament(props)
{
    /** Get Team Year **/
    const {id} = useLoaderData();
    const year = (id=> {
        switch (id) {
            case "1": return 2015;
            case "2": return 2016;
            case "3": return 2017;
            default: return "";
        }
    })(id);
    /** Get Teams List with Data  **/
    const {groupTeams, groups, games} = GetResult(year);

    let JSX = [];
    const Teams = {};

    /** Separate by groups if needed **/
    if(groups.length){
        for( let key in groupTeams){
            const g = groupTeams[key].group;
            if(!Teams[g]) Teams[g] = {};
            Teams[g][key] = groupTeams[key];
        }
        for (let groupName in Teams)
        {
            Teams[groupName] = SortTeamList(Teams[groupName]);
            JSX.push(<ResultTable
                key={groupName}
                teamList={Teams[groupName]}
                title={`группа ${groupName}`}
            />);
            JSX.push(<ChessTable
                key={1+groupName}
                teamList={Teams[groupName]} gameList={games}
            />);
        }
    }else{
        const Teams = SortTeamList(groupTeams);
        JSX.push(<ResultTable
            key={1}
            teamList={Teams}
            // title={`Команды ${year}г`}
        />)
    }
    return <Fragment><h1 className="tournament__title">{`Первый шаг - ${year}г`}</h1>{JSX}</Fragment>;
}

function SortTeamList(TeamList)
{
    return Object.values(TeamList).sort((a,b)=>{
        if(a.points>b.points) return -1
        else if(a.points<b.points) return 1;
        else if(a.ballsDiff>b.ballsDiff) return -1;
        else if(a.ballsDiff<b.ballsDiff) return 1;
        else return 0;
    })
}

function GetResult(Year)
{
    const {teams, groups, games} = DB;
    const currentGroup = Year || groups[0];
    const groupTeams = {};
    const subGroups = [];
    for (let key in teams[currentGroup])
    {
        let {id, name, sub} = teams[currentGroup][key];
        if(sub) sub = sub.toUpperCase();
        if(sub&&!subGroups.includes(sub)) subGroups.push(sub);
        groupTeams[key] = new Team(id, name, sub);
    }
    const groupGames = games[currentGroup];
    groupGames.forEach(game=>{
        const {t1Goals, t2Goals} = game;
        const team1 = 'id'+game.t1ID;
        const team2 = 'id'+game.t2ID;
        const gameDate = new Date(game.date);
        if(!(groupTeams[team1]&&groupTeams[team2])) return;
        if(gameDate.getTime() > Date.now()) return;
        groupTeams[team1].updateGameInfo(t1Goals, t2Goals);
        groupTeams[team2].updateGameInfo(t2Goals, t1Goals);
    })

    return {groupTeams, groups: subGroups,games: games[currentGroup]};
}